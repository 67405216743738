import { AdminDashboardStatBlocks } from "@/app/Dashboard/Components/AdminDashboardStatBlocks";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { DashboardPendingPaymentRequests } from "@/app/Dashboard/Components/DashboardPendingPaymentRequests";
import {
  BookingChangeRequestStatus,
  QueryBookingChangeRequestsWhereColumn,
} from "@/gql/graphql";
import { OrganisationDashboardStatBlocks } from "@/app/Dashboard/Components/OrganisationDashboardStatBlocks";
import { DashboardTaskList } from "@/app/Dashboard/Components/DashboardTaskList";
import { ChangeRequestTable } from "@/app/Bookings/Components/ChangeRequestTable";
import { Suspense } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { RelocationMultiTable } from "@/app/Relocations/Components/RelocationMultiTable";
import { useNavigate, useSearch } from "@tanstack/react-router";

export function DashboardScreen() {
  const { isAdmin } = useTenant();
  const { hasSupplier, supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(metaQuery, {});
  const { relocationStatus } = useSearch({
    from: "/_app/",
  });

  const navigate = useNavigate();

  return (
    <FullPageScreen className="space-y-12 p-4">
      {isAdmin && !hasSupplier ? (
        <>
          <Suspense fallback={<Skeleton className="h-[592px] w-full" />}>
            <AdminDashboardStatBlocks />
          </Suspense>
          <ChangeRequestTable
            id={"dashboard-pending-change-requests"}
            queryVariables={{
              where: {
                column: QueryBookingChangeRequestsWhereColumn.Status,
                value: BookingChangeRequestStatus.Pending,
              },
            }}
          />
          <DashboardPendingPaymentRequests />
        </>
      ) : (
        <>
          <OrganisationDashboardStatBlocks />
        </>
      )}

      {data?.meta?.pending_task_count ? <DashboardTaskList /> : null}
      <BookingTable
        id="dashboard-pending-bookings"
        title="Pending bookings"
        enabledStatuses={["pending"]}
      />

      <RelocationMultiTable
        tableId={"dashboard-relocations"}
        supplierId={supplierId ?? undefined}
        statuses={relocationStatus}
        getTabLinkProps={(status) => {
          return {
            to: "/",
            search: {
              relocationStatus: [status],
            },
          };
        }}
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/",
            search: {
              relocationStatus: newStatuses,
            },
          });
        }}
      />
    </FullPageScreen>
  );
}
