import { LinkProps, Outlet, useRouter } from "@tanstack/react-router";
import { FC, ReactNode, useState } from "react";
import { RecordScreenContentTabs } from "@/lib/Components/RecordScreen/Components/RecordScreenContentTabs";
import {
  Action,
  ActionGroupButton,
} from "@/lib/Components/Actions/ActionGroupButtons";
import { Button, ButtonProps } from "../Button/Button";
import classNames from "classnames";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { cn } from "@/lib/utils";
import { RecordScreenTitleCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenTitleCard";

export type RecordScreenLink = {
  name: string;
  Icon: FC<any>;
  notificationCount?: number;
  isHidden?: boolean;
} & LinkProps;

type RecordScreenProps = {
  title: string;
  titleIcon?: ReactNode;
  banner?: ReactNode;
  tabs?: RecordScreenLink[];
  actionsGroups?: ActionGroupButton[];
  badges?: ReactNode;
  subtitle: ReactNode;
  className?: string;
};

export function RecordScreen({
  tabs = [],
  actionsGroups = [],
  banner,
  title,
  subtitle,
  badges,
  className,
}: RecordScreenProps) {
  const { state } = useRouter();

  const isPending = state.isLoading;

  return (
    <>
      <RecordScreenHeader actionGroups={actionsGroups} />
      <div
        className={cn(
          "h-full overflow-y-scroll px-6",
          {
            "animate-pulse": isPending,
          },
          className,
        )}
      >
        <div className="mx-auto w-full max-w-6xl">
          <header className="space-y-4 pt-6">
            <RecordScreenTitleCard
              title={title}
              badges={badges}
              actionGroups={actionsGroups}
              subtitle={subtitle}
            />
            <RecordScreenContentTabs tabs={tabs} />
          </header>

          {banner ? <div className="space-y-2 py-6">{banner}</div> : null}
          <Outlet />

          <div className="h-96" />
        </div>
      </div>
    </>
  );
}

function RecordScreenHeader({
  actionGroups,
}: {
  actionGroups: ActionGroupButton[];
}) {
  const { isAdmin } = useTenant();

  const groups = actionGroups.flatMap((group) => {
    return group.items.map((item) =>
      item.actions.filter((action) => {
        const isHiddenBecauseNotAdmin = !!action.isAdminAction && !isAdmin;

        return (
          !action.isHidden && !action.isHiddenTopBar && !isHiddenBecauseNotAdmin
        );
      }),
    );
  });

  //Add some right padding to account for the missing scrollbar
  return (
    <div className="flex-shrink-0 border-b border-gray-200 bg-gray-100">
      {/* Toolbar*/}
      <div className="mx-auto flex h-16 w-full max-w-6xl flex-col justify-center">
        <div className="px-6">
          <div className="flex justify-between py-3">
            {/* Left buttons */}
            <div className="mr-2">
              <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                <span className="flex space-x-3">
                  {groups.map((value, index) => (
                    <ActionGroup group={value} key={index} />
                  ))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionGroup({ group }: { group: Action[] }) {
  if (group.length === 1) {
    return <ActionButton action={group[0]} />;
  }

  return (
    <span className="isolate inline-flex">
      {group.map((action, idx) => {
        return (
          <ActionButton
            key={idx}
            action={action}
            buttonProps={{
              roundedClass: classNames({
                "rounded-l-md": idx === 0,
                "rounded-r-md": idx === group.length - 1,
              }),
            }}
          />
        );
      })}
    </span>
  );
}

function ActionButton({
  action,
  buttonProps,
}: {
  action: Action;
  buttonProps?: Partial<ButtonProps>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      intent={action.intent}
      LeadingIcon={action.Icon}
      isLoading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        try {
          await action.handleAction();
        } finally {
          setIsLoading(false);
        }
      }}
      {...buttonProps}
    >
      {action.label}
    </Button>
  );
}
