import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";

export function SupplierBookingScreen() {
  const record = useSupplierRecord();

  return (
    <RecordScreenCard title="Bookings">
      <BookingTable
        id="supplier-bookings"
        enabledStatuses={["pending", "completed", "confirmed"]}
        queryVariables={{
          supplierId: record.id,
        }}
      />
    </RecordScreenCard>
  );
}
