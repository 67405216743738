import { InvoiceListSearchParams } from "@/app/Invoices/Routes/invoiceRoutes";
import { QueryInvoicesOrderByColumn, SortOrder } from "@/gql/graphql";
import { InvoiceListQueryVariables } from "@/app/Invoices/GraphQL/invoiceListQuery";

export function getInvoiceListQueryVariables(
  searchParams: InvoiceListSearchParams,
): InvoiceListQueryVariables {
  const baseParams = {
    first: 20,
    page: 1,
    search: searchParams.invoiceSearch || undefined,
    status: searchParams.invoiceStatus,
  };

  if (searchParams.invoiceSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryInvoicesOrderByColumn.Id,
      },
    ],
  };
}
