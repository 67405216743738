import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { memo, useCallback, useState } from "react";
import { GoogleMapsLoader } from "@/lib/GoogleMaps/Components/GoogleMapsLoader";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from "@react-google-maps/api";
import { Measurement, RelocationFieldsFragment } from "@/gql/graphql";
import { Separator } from "@/components/ui/separator";
import {
  formatCurrency,
  formatDistance,
  formatDuration,
} from "@/lib/Formatters/formatCurrency";
import dayjs from "dayjs";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { BookingEditReservationDetailsDialog } from "@/app/Bookings/Components/Dialogs/BookingEditReservationDetailsDialog";
import { formatDate } from "@/lib/Formatters/formatDate";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";

export function BookingTripCard({ booking }: { booking: BookingRecord }) {
  const { open } = useDialog(BookingEditReservationDetailsDialog);

  return (
    <Card className="overflow-hidden">
      <Directions
        departureOffice={booking.relocation.departureOffice}
        deliveryOffice={booking.relocation.deliveryOffice}
        measurement={booking.relocation.measurement}
      />

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3 relative">
          <div className="absolute right-0 -top-2">
            <Button
              size="icon"
              variant="outline"
              className="h-8 w-8"
              onClick={() => {
                open({
                  bookingId: booking.id,
                });
              }}
            >
              <Pencil className="h-3.5 w-3.5" />
            </Button>
          </div>
          <div className="font-semibold">Trip</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Duration</dt>
              <dd>
                {formatDuration(
                  booking.discounted_units,
                  booking.full_price_units,
                  booking.relocation.hire_unit_type,
                )}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Distance</dt>
              <dd>
                {formatDistance(
                  booking.distance_allowed,
                  booking.relocation.measurement,
                )}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Vehicle</dt>
              <dd>
                {booking.relocation.vehicle.name}
                {` (${booking.relocation.vehicle.code})`}
              </dd>
            </div>
            {booking.relocationLineReference?.reference ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Rego/Line</dt>
                <dd>{booking.relocationLineReference?.reference}</dd>
              </div>
            ) : null}
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid grid-cols-2 gap-4">
          <div className="grid gap-3">
            <div className="font-semibold">Pickup Information</div>
            <div className="grid gap-0.5 text-muted-foreground">
              <span>{dayjs(booking.depart_at).format("h:ssa dddd")}</span>
              <span>{dayjs(booking.depart_at).format("LL")}</span>
            </div>

            <address className="grid gap-0.5 not-italic text-muted-foreground">
              <p className="">{booking.relocation.departureOffice.name}</p>
              <p>
                {
                  booking.relocation.departureOffice.address
                    .formatted_address_line_1
                }
              </p>
              <p>
                {
                  booking.relocation.departureOffice.address
                    .formatted_address_line_2
                }
              </p>
            </address>
          </div>
          <div className="grid auto-rows-max gap-3">
            <div className="font-semibold">Drop off Information</div>
            <div className="grid gap-0.5 text-muted-foreground">
              <span>{dayjs(booking.deliver_at).format("h:ssa dddd")}</span>
              <span>{dayjs(booking.deliver_at).format("LL")}</span>
            </div>

            <address className="grid gap-0.5 not-italic text-muted-foreground">
              <p className="text-muted-foreground">
                {booking.relocation.deliveryOffice.name}
              </p>
              <p>
                {
                  booking.relocation.deliveryOffice.address
                    .formatted_address_line_1
                }
              </p>
              <p>
                {
                  booking.relocation.deliveryOffice.address
                    .formatted_address_line_2
                }
              </p>
            </address>
          </div>
        </div>
        {booking.relocation.inclusions.length ? (
          <>
            <Separator className="my-4" />
            <div className="grid gap-3">
              <div className="font-semibold">Inclusions</div>
              <dl className="grid gap-3">
                {booking.relocation.inclusions.map((inclusion) => (
                  <div
                    className="flex items-center justify-between"
                    key={inclusion.id}
                  >
                    <dt className="flex items-center gap-1 text-muted-foreground">
                      {inclusion.type}
                    </dt>
                    <dd>
                      <div>
                        {formatCurrency(
                          inclusion.value,
                          booking.relocation.currency,
                        )}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: inclusion.description ?? "--",
                        }}
                      />
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </>
        ) : null}
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Notes</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">
                {booking.relocation.supplier.name} reference
              </dt>
              <dd>
                <div>{booking.supplier_reference ?? "--"}</div>
              </dd>
            </div>

            {booking.preferred_ferry_date ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Preferred ferry date</dt>
                <dd>
                  <div>{formatDate(booking.preferred_ferry_date)}</div>
                </dd>
              </div>
            ) : null}

            {booking.customer_note ? (
              <div className="flex flex-col space-y-3">
                <dt className="text-muted-foreground">Customer note</dt>
                <dd>
                  <div
                    className="prose prose-sm bg-gray-50 rounded-md p-3 w-full text-muted-foreground"
                    dangerouslySetInnerHTML={{
                      __html: booking.customer_note ?? "--",
                    }}
                  />
                </dd>
              </div>
            ) : null}

            {booking.ferry_note ? (
              <div className="flex flex-col space-y-3">
                <dt className="text-muted-foreground">Ferry note</dt>
                <dd>
                  <div
                    className="prose prose-sm bg-gray-50 rounded-md p-3 w-full text-muted-foreground"
                    dangerouslySetInnerHTML={{
                      __html: booking.ferry_note ?? "--",
                    }}
                  />
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
      </CardContent>
      <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
        <div className="text-xs text-muted-foreground">
          <a
            className="hover:text-gray-700 hover:underline"
            target="_blank"
            rel="noreferrer"
            href={new URL(
              `/en/relocations/${booking.relocation.id}`,
              import.meta.env.VITE_APP_URL,
            ).toString()}
          >
            View listing <ArrowTopRightOnSquareIcon className="size-4 inline" />
          </a>
        </div>
      </CardFooter>
    </Card>
  );
}

type DirectionRendererProps = {
  departureOffice: RelocationFieldsFragment["departureOffice"];
  deliveryOffice: RelocationFieldsFragment["deliveryOffice"];
  measurement: Measurement;
  onChange?: (values: { hours: number; distance: number }) => void;
};

const DirectionsServiceMemo = memo(DirectionsService, (oldProps, newProps) => {
  return JSON.stringify(oldProps.options) === JSON.stringify(newProps.options);
});

export function Directions({
  departureOffice,
  deliveryOffice,
  measurement,
}: DirectionRendererProps) {
  const [response, setResponse] = useState<google.maps.DirectionsResult | null>(
    null,
  );

  const directionsCallback = useCallback(
    (
      result: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus,
    ) => {
      if (result !== null && status === "OK") {
        setResponse(result);
      } else {
        setResponse(null);
      }
    },
    [departureOffice, deliveryOffice, measurement],
  );

  const origin = {
    lat: departureOffice.address.lat!,
    lng: departureOffice.address.lng!,
  };

  const destination = {
    lat: deliveryOffice.address.lat!,
    lng: deliveryOffice.address.lng!,
  };

  return (
    <GoogleMapsLoader>
      <GoogleMap
        mapContainerClassName="h-40"
        options={{
          scrollwheel: false,
          disableDefaultUI: true,
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#1d2c4d",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8ec3b9",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1a3646",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#4b6878",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#64779e",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#4b6878",
                },
              ],
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#334e87",
                },
              ],
            },
            {
              featureType: "landscape.natural",
              elementType: "geometry",
              stylers: [
                {
                  color: "#023e58",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#283d6a",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#6f9ba5",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1d2c4d",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#023e58",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3C7680",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#304a7d",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#98a5be",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1d2c4d",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#2c6675",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#255763",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#b0d5ce",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#023e58",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#98a5be",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1d2c4d",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#283d6a",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3a4762",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#0e1626",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#4e6d70",
                },
              ],
            },
          ],
        }}
      >
        {origin && destination && (
          <DirectionsServiceMemo
            options={{
              travelMode: "DRIVING" as any,
              origin,
              destination,
              unitSystem: measurement === Measurement.Metric ? 0 : 1,
            }}
            callback={directionsCallback}
          />
        )}

        {response ? (
          <DirectionsRenderer options={{ directions: response }} />
        ) : null}
      </GoogleMap>
    </GoogleMapsLoader>
  );
}
