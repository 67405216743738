import { useMatch } from "@tanstack/react-router";
import { vehicleRoute } from "@/app/Vehicles/Routes/vehicleRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { vehicleRecordQuery } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";

export function useVehicleRecord() {
  const match = useMatch({
    from: vehicleRoute.id,
  });

  const { data } = useSuspenseGqlQuery(vehicleRecordQuery, {
    id: match.params.id,
  });

  return data.vehicle;
}
