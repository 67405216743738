import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { ConfirmDeleteDialog } from "@/lib/Components/Dialog/ConfirmDeleteDialog";
import { ArchiveBoxIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "@tanstack/react-router";
import { AssignOrganisationDialog } from "@/app/Suppliers/Components/AssignOrganisationDialog";
import { LinkIcon } from "lucide-react";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { supplierCreateRoute } from "@/app/Suppliers/Routes/supplierRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { importRelocationsMutation } from "@/app/Import/GraphQL/importMutations";
import { SupplierListItem } from "@/app/Suppliers/GraphQL/supplierListQuery";
import { SwitchIcon } from "@radix-ui/react-icons";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function useSupplierActions(): ActionsHook<SupplierListItem> {
  const { open } = useDialog(ConfirmDeleteDialog);
  const { mutateAsync } = useGqlMutation(deleteSupplierMutation);
  const navigate = useNavigate();
  const { isAdmin } = useTenant();
  const { open: assignOrg } = useDialog(AssignOrganisationDialog);
  const { mutateAsync: importRelocations } = useGqlMutation(
    importRelocationsMutation,
  );
  const { actions, supplierId } = useGlobalSupplier();

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Make current",
                isHidden: supplierId === model.id,
                Icon: SwitchIcon,
                handleAction: () => {
                  actions.setSupplier(model.id);
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "View relocations",
                Icon: ArrowTopRightOnSquareIcon,
                intent: "neutral",
                handleAction: () => {
                  window.open(
                    new URL(
                      `/en/relocations/?supplier_id=${model.id}`,
                      import.meta.env.VITE_APP_URL,
                    ),
                    "_blank",
                  );
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Assign organisation",
                handleAction: () => {
                  assignOrg({
                    supplierId: model.id,
                  });
                },
                Icon: LinkIcon,
                isHiddenTopBar: true,
                isHidden: !isAdmin,
              },
            ],
          },
          {
            actions: [
              {
                label: "Expire relocations",
                Icon: ArchiveBoxIcon,
                isHiddenTopBar: true,
                handleAction: async () => {
                  await importRelocations({
                    input: {
                      supplierId: model.id,
                      relocations: [],
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              ...(isAdmin
                ? [
                    {
                      label: "Delete",
                      Icon: TrashIcon,
                      intent: "danger",
                      isHiddenTopBar: true,
                      handleAction: () => {
                        open({
                          message:
                            "Are you sure you want to delete this supplier?",
                          buttonText: `Delete ${model.name}`,
                          title: `Delete ${model.name}`,
                          onDelete: async () => {
                            await mutateAsync({ id: model.id });
                            await navigate({
                              to: supplierCreateRoute.to,
                            });
                          },
                        });
                      },
                    } as const,
                  ]
                : []),
            ],
          },
        ],
      },
    ];
  };
}
