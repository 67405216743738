import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { RecordCreateScreen } from "@/lib/Components/RecordScreen/RecordCreateScreen";
import { CreateBookingInput, Region } from "@/gql/graphql";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { RelocationInput } from "@/app/Relocations/Components/RelocationInput";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { DateTimeInput } from "@/lib/Components/Form/Inputs/DateTimeInput";
import { bookingRoute } from "@/app/Bookings/Routes/bookingRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createBookingMutation } from "@/app/Bookings/GraphQL/bookingMutations";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

export function BookingCreateScreen() {
  const { mutateAsync } = useGqlMutation(createBookingMutation);
  const navigate = useNavigate();
  const [relocation, setRelocation] = useState<RelocationListItem | null>(null);
  const { isAdmin } = useTenant();

  if (!isAdmin) {
    return <div>You do not have the permissions to access this page</div>;
  }

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateBookingInput>
        initialValues={{
          name: null!,
          email: null!,
          depart_at: null!,
          deliver_at: null!,
          number_of_travellers: null!,
          date_of_birth: null!,
          phone: null!,
          relocation: null!,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          await navigate({
            to: bookingRoute.to,
            params: {
              bookingId: res.createBooking.id,
            },
          });
        }}
      >
        <RelocationInput
          name="relocation.connect"
          label="Relocation"
          onChange={setRelocation}
        />
        <FormDivider />

        <DateTimeInput name={"depart_at"} label="Depart at" />
        <DateTimeInput name={"deliver_at"} label="Deliver at" />

        <FormDivider />

        <TextInput name={"name"} label={"Name"} />
        <TextInput type="email" name={"email"} label={"Email"} />
        <PhoneInput
          name={"phone"}
          label={"Phone"}
          defaultRegion={relocation?.region ?? Region.Au}
        />
        <NumberInput
          name={"number_of_travellers"}
          label={"Number of travellers"}
        />
        <DateInput name={"date_of_birth"} label={"Date of birth"} />
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
