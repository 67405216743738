import {
  SidebarList,
  SidebarListItemProps,
} from "@/lib/Components/SideBarList/SideBarList";
import { useDeferredValue, useEffect, useState } from "react";
import { contactTypeColorMap } from "@/app/Contacts/Utils/contactTypeColorMap";
import {
  ContactListItem,
  contactListQuery,
} from "@/app/Contacts/GraphQL/contactListQuery";
import { Badge } from "@/components/catalyst/badge";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { getContactListQueryVariables } from "@/app/Contacts/Utils/getContactListQueryVariables";
import { keepPreviousData } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { ContactIcon } from "@/app/Contacts/Components/ContactIcon";
import { useTranslation } from "react-i18next";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";

export function ContactListScreen() {
  const searchParams = useSearch({
    from: "/_app/contacts",
  });
  const navigate = useNavigate();
  const [search, setSearch] = useState(searchParams.contactSearch ?? "");
  const deferredSearch = useDeferredValue(search);
  useEffect(() => {
    navigate({
      search: (s) => ({
        ...s,
        contactSearch: deferredSearch || undefined,
      }),
    });
  }, [deferredSearch]);

  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useInfiniteGqlQuery(
      contactListQuery,
      getContactListQueryVariables({ ...searchParams, contactSearch: search }),
      {
        placeholderData: keepPreviousData,
        initialPageParam: {
          page: 1,
        },
        getNextPageParam: (lastPage) => {
          return lastPage.contacts.paginatorInfo.hasMorePages
            ? {
                page: lastPage.contacts.paginatorInfo.currentPage + 1,
              }
            : undefined;
        },
      },
    );

  const items = data?.pages.flatMap((p) => p.contacts.data);
  const totalCount = data?.pages.at(0)?.contacts.paginatorInfo.total ?? 0;

  return (
    <SidebarList<ContactListItem>
      fetchNextPage={fetchNextPage}
      search={search}
      onSearchChange={setSearch}
      listRoute="/contacts"
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      itemNode={(item) => <ContactNode model={item} />}
      items={items}
      totalCount={totalCount}
      linkProps={(item) => ({
        to: "/contacts/$id",
        params: {
          id: item.id,
        },
      })}
      onClearFilters={() => {
        setSearch("");
      }}
    />
  );
}

function ContactNode({ model }: SidebarListItemProps<ContactListItem>) {
  const { t } = useTranslation("contact");

  return (
    <div className="relative flex items-center space-x-2 text-xs">
      <div className="-ml-3">
        <ContactIcon model={model} className="size-10" />
      </div>
      <div className="absolute top-0 right-0">
        <Badge color={contactTypeColorMap[model.type]}>
          {t(`type.${model.type}`)}
        </Badge>
      </div>
      <div>
        <p className="font-semibold">{model.name}</p>
        <p className="text-gray-500 text-xs mt-1">
          {model.emails.at(0)?.email}
        </p>

        <p className="text-gray-500 text-xs mt-1">
          <TimeSinceNow time={model.created_at} />
        </p>
      </div>
    </div>
  );
}
