import { RelocationStatus } from "@/gql/graphql";
import { FC } from "react";
import {
  ArchiveBoxIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";
import { TagsIcon, TimerOffIcon } from "lucide-react";
import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";

export const relocationStatusMap: {
  [key in RelocationStatus]: {
    label: string;
    color: string;
    Icon: FC<any>;
    transitions: RelocationStatus[];
  };
} = {
  [RelocationStatus.Archived]: {
    label: "Archived",
    color: "text-purple-500",
    Icon: ArchiveBoxIcon,
    transitions: [RelocationStatus.Ready, RelocationStatus.Paused],
  },
  [RelocationStatus.Draft]: {
    label: "Draft",
    color: "text-gray-500",
    Icon: CubeTransparentIcon,
    transitions: [RelocationStatus.Ready, RelocationStatus.Archived],
  },
  [RelocationStatus.Expired]: {
    label: "Expired",
    color: "text-red-500",
    Icon: TimerOffIcon,
    transitions: [RelocationStatus.Ready, RelocationStatus.Archived],
  },
  [RelocationStatus.Ready]: {
    label: "Ready",
    color: "text-green-500",
    Icon: PlayIcon,
    transitions: [RelocationStatus.Paused, RelocationStatus.Archived],
  },
  [RelocationStatus.Paused]: {
    label: "Paused",
    color: "text-gray-500",
    Icon: PauseIcon,
    transitions: [RelocationStatus.Ready, RelocationStatus.Archived],
  },
  [RelocationStatus.SoldOut]: {
    label: "Sold out",
    color: "text-yellow-500",
    Icon: TagsIcon,
    transitions: [RelocationStatus.Ready, RelocationStatus.Archived],
  },
};
