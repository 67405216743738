import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { ModelType } from "@/gql/graphql";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";

export function OfficeActivityScreen() {
  const record = useOfficeRecord();

  return (
    <RecordScreenCard title={"Activity logs"}>
      <ActivityLogFeed
        modelId={record.id}
        modelType={ModelType.SupplierOffice}
      />
    </RecordScreenCard>
  );
}
