import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import {
  BuildingOffice2Icon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { ContactIcon } from "@/app/Contacts/Components/ContactIcon";
import { DollarSignIcon, FileSpreadsheetIcon } from "lucide-react";
import { useContactRecord } from "@/app/Contacts/Hooks/useContactRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { ContactType } from "@/gql/graphql";

export function ContactRecordScreen() {
  const contact = useContactRecord();

  return (
    <RecordScreen
      title={contact.name ?? "--"}
      subtitle={`Created ${formatDateTimeSinceNow(contact.created_at)}`}
      titleIcon={<ContactIcon model={contact} />}
      tabs={[
        {
          name: "Details",
          to: "/contacts/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Entities",
          to: "/contacts/$id/entities",
          Icon: BuildingOffice2Icon,
          isHidden: contact?.type !== ContactType.Organisation,
        },
        {
          name: "Invoices",
          to: "/contacts/$id/invoices",
          Icon: DollarSignIcon,
        },
        {
          name: "Statements",
          to: "/contacts/$id/statements",
          Icon: FileSpreadsheetIcon,
        },
      ]}
    />
  );
}
