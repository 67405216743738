import { Link, LinkProps } from "@tanstack/react-router";
import {
  BanknotesIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CogIcon,
  CurrencyDollarIcon,
  PlusIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftRightIcon,
  BookMarkedIcon,
  BookTemplateIcon,
  Building2Icon,
  CalendarCheck,
  CalendarCheck2Icon,
  CalendarClockIcon,
  CalendarMinus,
  ClipboardXIcon,
  Contact2Icon,
  GhostIcon,
  LucideCrown,
  PenToolIcon,
} from "lucide-react";
import { importRoute, toolsRoute, waitlistsRoute } from "@/routes";
import {
  BookingStatus,
  ContactType,
  InvoiceStatus,
  RelocationStatus,
} from "@/gql/graphql";
import { UserButton, useUser } from "@clerk/clerk-react";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { CrossCircledIcon, StopwatchIcon } from "@radix-ui/react-icons";
import { HomeIcon } from "@heroicons/react/24/solid";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { bookingsRoute } from "@/app/Bookings/Routes/bookingRoutes";
import { invoiceListRoute } from "@/app/Invoices/Routes/invoiceRoutes";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { sidebarCountsQuery } from "@/lib/Components/Layout/Queries/sidebarCountsQuery";
import { paymentListRoute } from "@/app/Payments/Routes/paymentRoute";
import { paidDriversListRoute } from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import { contactsRoute } from "@/app/Contacts/Routes/contactRoute";
import { relocationsRoute } from "@/app/Relocations/Routes/relocationRoute";
import { SidebarHeaderDropdown } from "@/lib/Components/Layout/Sidebar/SidebarHeaderDropdown";

import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { settingsRoute } from "@/app/Settings/Settings/Routes/settingsRoute";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { useLocalStorage } from "react-use";
import { signInRoute } from "@/app/Auth/Routes/loginRoutes";

type SideBarItem = {
  name: string;
  icon: FC<any>;
  subItems?: SideBarChild[];
  isHidden?: boolean;
} & LinkProps;

type SideBarChild = LinkProps & {
  name: string;
  icon: FC<any>;
  count?: number;
};

export function Sidebar({ className }: { className?: string }) {
  const { tenantType, isAdmin } = useTenant();
  const { supplier, hasSupplier } = useGlobalSupplier();
  const { data } = useGqlQuery(sidebarCountsQuery, {});
  const [showSelectBrand, setShowSelectBrand] = useLocalStorage(
    "show-select-brand",
    true,
  );

  const pendingCount = data?.bookingsCount?.find(
    (b) => b.status === BookingStatus.AwaitingConfirmation,
  )?.count;

  const vipCount = data?.bookingsCount?.find(
    (b) => b.status === BookingStatus.Vip,
  )?.count;

  const draftInvoicesCount = data?.invoiceCounts?.find(
    (r) => r.status === InvoiceStatus.Draft,
  )?.count;

  const dueInvoicesCount = data?.invoiceCounts?.reduce((acc, i) => {
    if ([InvoiceStatus.Issued, InvoiceStatus.Overdue].includes(i.status)) {
      acc += i.count;
    }

    return acc;
  }, 0);

  const overdueInvoicesCount = data?.invoiceCounts?.find(
    (i) => i.status === InvoiceStatus.Overdue,
  )?.count;

  const primaryNavigation: SideBarItem[] = [
    {
      name: "Dashboard",
      to: "/",
      icon: HomeIcon,
    },
    {
      name: "Bookings",
      to: bookingsRoute.to,
      icon: CalendarDaysIcon,
      subItems: [
        {
          name: "Pending",
          count: pendingCount,
          icon: BookTemplateIcon,
          to: bookingsRoute.to,
          search: {
            bookingStatus: [BookingStatus.AwaitingConfirmation],
          },
        },
        ...(isAdmin
          ? [
              {
                name: "VIP",
                to: bookingsRoute.to,
                icon: LucideCrown,
                search: {
                  bookingStatus: [BookingStatus.Vip],
                },
                count: vipCount,
              },
            ]
          : []),
        {
          name: "Confirmed",
          to: bookingsRoute.to,
          icon: CalendarCheck,
          search: {
            bookingStatus: [BookingStatus.Confirmed, BookingStatus.Completed],
          },
        },
        {
          name: "Cancelled",
          to: bookingsRoute.to,
          icon: ClipboardXIcon,
          search: {
            bookingStatus: [
              BookingStatus.AdminCancelled,
              BookingStatus.SupplierCancelled,
              BookingStatus.SupplierCancelledNotAvailable,
              BookingStatus.SupplierCancelledNoFerry,
              BookingStatus.SupplierCancelledMechanicalFailure,
              BookingStatus.CustomerCancelled,
              BookingStatus.CustomerCancelledRebooked,
              BookingStatus.CustomerCancelledConfirmationTimeliness,
              BookingStatus.CustomerCancelledNoShow,
            ],
          },
        },
        ...(isAdmin
          ? [
              {
                name: "Incomplete",
                to: bookingsRoute.to,
                params: (params: any) => params,
                icon: XCircleIcon,
                search: {
                  bookingStatus: [BookingStatus.AwaitingPayment],
                },
              } satisfies SideBarChild,
            ]
          : []),
      ],
    },
    {
      name: "Relocations",
      to: relocationsRoute.to,
      search: {
        relocationStatus: [RelocationStatus.Ready],
      },
      icon: ArrowLeftRightIcon,
    },
    {
      name: "Suppliers",
      to: "/suppliers",
      icon: Building2Icon,
      isHidden: hasSupplier,
    },
    {
      name: "Invoices",
      icon: BanknotesIcon,
      to: invoiceListRoute.to,
      isHidden: hasSupplier,
      subItems: [
        {
          name: "Due",
          to: invoiceListRoute.to,
          icon: CalendarCheck2Icon,
          count: dueInvoicesCount,
          search: {
            invoiceStatus: [InvoiceStatus.Issued, InvoiceStatus.Overdue],
          },
        },
        {
          name: "Overdue",
          count: overdueInvoicesCount,
          to: invoiceListRoute.to,
          icon: CalendarClockIcon,
          search: {
            invoiceStatus: [InvoiceStatus.Overdue],
          },
        },
        ...(isAdmin
          ? [
              {
                name: "Draft",
                count: draftInvoicesCount,
                to: invoiceListRoute.to,
                icon: CalendarMinus,
                search: {
                  invoiceStatus: [InvoiceStatus.Draft],
                },
              },
              {
                name: "All payments",
                to: paymentListRoute.to,
                icon: CurrencyDollarIcon,
              },
            ]
          : []),
      ],
    },
    ...(isAdmin
      ? [
          {
            name: "Waitlists",
            to: waitlistsRoute.to,
            icon: StopwatchIcon,
          },
          {
            name: "Paid drivers",
            icon: Contact2Icon,
            to: paidDriversListRoute.to,
          },
          {
            name: "Contacts",
            to: contactsRoute.to,
            icon: UserGroupIcon,
            subItems: [
              {
                name: "Users",
                icon: BookMarkedIcon,
                to: contactsRoute.to,
                search: (s) => ({
                  ...s,
                  contactType: [ContactType.User],
                }),
              } satisfies SideBarChild,
              {
                name: "Organisations",
                to: contactsRoute.to,
                icon: BuildingOffice2Icon,
                search: {
                  contactType: [ContactType.Organisation],
                },
              },
              {
                name: "Guests",
                to: contactsRoute.to,
                icon: GhostIcon,
                search: {
                  contactType: [ContactType.Guest],
                },
              },
            ],
          },
        ]
      : []),
  ];

  const tertiaryNavigation: SideBarItem[] = [
    {
      name: "Settings",
      to: settingsRoute.to,
      icon: CogIcon,
    },
    {
      name: "Import",
      to: importRoute.to,
      icon: PlusIcon,
    },
    ...(tenantType === "admin"
      ? [
          {
            name: "Tools",
            to: toolsRoute.to,
            icon: PenToolIcon,
          },
        ]
      : []),
  ];

  const navItems: SideBarItem[][] = [primaryNavigation, tertiaryNavigation];

  return (
    <aside className={cn("w-52 pt-4 flex-col min-h-0", className)}>
      <div className="px-2">
        <SidebarHeaderDropdown />

        {!supplier && showSelectBrand ? (
          <div className="flex justify-between items-center mb-2">
            <div className="text-yellow-200 text-xs">👆 Select your brand</div>

            <IconButton
              onClick={() => setShowSelectBrand(false)}
              className="p-1"
              size={3}
              Icon={CrossCircledIcon}
              tooltip="dismiss"
            />
          </div>
        ) : null}

        {isAdmin ? (
          <div className="border-2 border-orange-400 text-orange-400 font-bold border-dashed rounded-md overflow-hidden text-center p-2">
            ADMIN VIEW
          </div>
        ) : null}
      </div>

      <div className="flex-grow min-h-0">
        <ScrollArea className="h-full">
          <nav className="flex flex-col space-y-4" aria-label="Sidebar">
            {navItems.map((navItem, index) => (
              <div className="space-y-1 px-2 pt-4" key={index}>
                {navItem.map((item, idx) => {
                  if (item.isHidden) {
                    return null;
                  }

                  return <NavItem item={item} key={idx} />;
                })}
              </div>
            ))}
          </nav>
        </ScrollArea>
      </div>
      <UserFooter className="flex-shrink-0" />
    </aside>
  );
}

export function UserFooter({ className }: { className?: string }) {
  const { user } = useUser();
  const { hasSupplier } = useGlobalSupplier();

  return (
    <div
      className={cn(
        "flex border-t px-4 py-3",
        {
          "border-indigo-800": hasSupplier,
          "border-fuchsia-800": !hasSupplier,
        },
        className,
      )}
    >
      <UserButton afterSignOutUrl={signInRoute.to} />
      <div className="ml-3 flex-grow">
        <p className="text-left text-sm font-medium text-white line-clamp-2">
          {user?.fullName ?? "--"}
        </p>
        <p className="text-left text-xs font-medium text-indigo-200 group-hover:text-white">
          {user?.primaryEmailAddress?.emailAddress ?? "--"}
        </p>
      </div>
    </div>
  );
}

function NavItem({ item }: { item: SideBarItem }) {
  const [open, setOpen] = useState(false);
  const { hasSupplier } = useGlobalSupplier();

  return (
    <Collapsible open={open}>
      <Link
        activeProps={() => ({
          className: cn(`font-bold text-white`, {
            "bg-blue-800": hasSupplier,
            "bg-fuchsia-800": !hasSupplier,
          }),
        })}
        to={item.to}
        params={item.params}
        search={item.search}
        className={cn(
          "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 hover:text-white",
          {
            "text-blue-100 hover:bg-blue-600": hasSupplier,
            "text-fuchsia-100 hover:bg-fuchsia-600": !hasSupplier,
          },
        )}
        aria-current="page"
      >
        <item.icon
          className={cn("mr-4 h-6 w-6 flex-shrink-0", {
            "text-fuchsia-200": !hasSupplier,
            "text-blue-200": hasSupplier,
          })}
          aria-hidden="true"
        />
        <span className="flex-grow">{item.name}</span>

        {item.subItems && (
          <CollapsibleTrigger
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            <div
              className={cn("p-1 rounded-full transition-colors", {
                "text-blue-100 hover:bg-blue-400": hasSupplier,
                "text-fuchsia-100 hover:bg-fuchsia-400": !hasSupplier,
              })}
            >
              <ChevronDownIcon className="w-4 h-4" />
            </div>
          </CollapsibleTrigger>
        )}
      </Link>

      {item.subItems && (
        <CollapsibleContent className="CollapsibleContent">
          <div className="ml-3 pl-3 mt-3 relative space-y-1">
            <div className="absolute left-0 h-full py-5">
              <div
                className={cn("border-l h-full", {
                  "border-fuchsia-400": !hasSupplier,
                  "border-blue-400": hasSupplier,
                })}
              />
            </div>
            {item.subItems?.map((child, idx) => (
              <Link
                key={idx}
                params={child.params}
                search={child.search}
                activeOptions={{
                  includeSearch: true,
                }}
                activeProps={() => ({
                  className: cn(`font-bold bg-blue-800 text-white`, {
                    "bg-fuchsia-800": !hasSupplier,
                  }),
                })}
                to={child.to}
                className={cn(
                  "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-blue-100 hover:bg-blue-600 hover:text-white relative",
                  {
                    "text-fuchsia-100 hover:bg-fuchsia-600": !hasSupplier,
                  },
                )}
                aria-current="page"
              >
                <span
                  className={cn("border-t absolute -left-3 w-3", {
                    "border-fuchsia-400": !hasSupplier,
                    "border-blue-400": hasSupplier,
                  })}
                />
                <span className="flex-grow flex items-center">
                  <child.icon
                    className={cn("mr-4 h-6 w-6 flex-shrink-0", {
                      "text-fuchsia-200": !hasSupplier,
                      "text-blue-200": hasSupplier,
                    })}
                    aria-hidden="true"
                  />
                  <span className="flex-grow">{child.name}</span>
                  {child.count ? <span>{child.count}</span> : null}
                </span>
              </Link>
            ))}
          </div>
        </CollapsibleContent>
      )}
    </Collapsible>
  );
}
