import { EmptyRecordScreen } from "@/lib/Components/Screens/EmptyRecordScreen";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { invoiceCreateRoute } from "@/app/Invoices/Routes/invoiceRoutes";

export function InvoiceEmptyScreen() {
  const { isAdmin } = useTenant();

  return (
    <EmptyRecordScreen
      title="Select an invoice"
      items={[
        ...(isAdmin
          ? [
              {
                name: "Create an invoice",
                description: "Manually create an invoice",
                Icon: PlusIcon,
                toOptions: {
                  to: invoiceCreateRoute.to,
                },
              },
            ]
          : []),
      ]}
      description="Select an invoice or chose from the options below"
      flair="Invoices"
    />
  );
}
