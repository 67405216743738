import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { WaitlistTable } from "@/app/Waitlists/Components/WaitlistTable";
import { UserWaitlistScreen } from "@/app/Waitlists/Screens/UserWaitlistScreen";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";

export function WaitlistsScreen() {
  return (
    <FullPageScreen>
      <Tabs defaultValue="users" className="w-full">
        <TabsList>
          <TabsTrigger value="users">All users</TabsTrigger>
          <TabsTrigger value="waitlists">All waitlists</TabsTrigger>
        </TabsList>
        <TabsContent value="users">
          <UserWaitlistScreen />
        </TabsContent>
        <TabsContent value="waitlists">
          <WaitlistTable />
        </TabsContent>
      </Tabs>
    </FullPageScreen>
  );
}
