import { z } from "zod";

export const contactEmailValidation = z
  .object({
    emails: z.object({
      upsert: z.array(
        z.object({
          email: z.string().email(),
          is_billing: z.boolean(),
          is_primary: z.boolean(),
          is_invoice_reminder: z.boolean(),
        }),
      ),
      delete: z.array(z.string()),
    }),
  })
  .superRefine((data, ctx) => {
    console.log(
      "data.emails.upsert",
      data.emails.upsert,
      data.emails.upsert.filter((email) => email.is_billing),
      data.emails.upsert.filter((email) => email.is_billing).length === 0,
    );

    if (data.emails.upsert.filter((email) => email.is_billing).length === 0) {
      ctx.addIssue({
        code: "custom",
        message: "At least one email must be marked as billing",
        path: ["emails", "upsert", 0, "is_billing"],
      });
    }
  });
