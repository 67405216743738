import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { formatDate } from "@/lib/Formatters/formatDate";
import { TableList } from "@/lib/Components/Tables/TableList";
import {
  WaitlistListItem,
  waitlistListQuery,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import { getTableProps } from "@/lib/Components/Tables/Utils/getTableProps";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";

export function RelocationWaitlistScreen() {
  const record = useRelocationRecord();

  const columns: ColumnDef<WaitlistListItem>[] = [
    {
      header: "Trip",
      cell: TripCell,
    },
    {
      header: "Who",
      cell: PersonCell,
    },
    {
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  const props = getTableProps({
    document: waitlistListQuery,
    accessor: (data) => data.waitlists,
    columns,
  });

  return (
    <RecordScreenCard title="Waitlists">
      <TableList
        {...props}
        queryVariables={{
          relocationId: record.id,
        }}
      />
    </RecordScreenCard>
  );
}

function TripCell({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;

  return (
    <div>
      <p>{waitlist.id}</p>
      <p className="text-gray-500">
        {formatDate(waitlist.earliest_departure_date)}
        {" - "}
        {formatDate(waitlist.latest_departure_date)}
      </p>
    </div>
  );
}

function PersonCell({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;

  return (
    <div>
      <p>{row.original.user?.name?.trim() || "--"}</p>
      <p className="text-gray-500">
        <a href={`mailto:${row.original.user.primary_email}`}>
          {row.original.user.primary_email || "--"}
        </a>
      </p>
      <p>
        <a href={`tel:${waitlist?.user.primary_phone}`}>
          {waitlist?.user.primary_phone ?? "--"}
        </a>
      </p>
    </div>
  );
}
