import { useNavigate } from "@tanstack/react-router";
import { useDebounce } from "react-use";

export function useDeferredRouting<T>({
  from,
  searchParams,
}: {
  from: any;
  searchParams: T;
}) {
  const navigate = useNavigate({
    from,
  });

  useDebounce(
    () => {
      navigate({
        search: (s) => ({
          ...s,
          ...searchParams,
        }),
        replace: true,
      });
    },
    500,
    [JSON.stringify(searchParams)],
  );
}
