import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { RelocationStatus } from "@/gql/graphql";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link, useMatch, useNavigate } from "@tanstack/react-router";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationCreateDialog } from "@/app/Relocations/Components/RelocationCreateDialog";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationStatusCountsQuery } from "@/app/Relocations/GraphQL/relocationStatusCountsQuery";
import { Badge } from "@/components/catalyst/badge";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

type Filter = {
  label: ReactNode;
  statuses: RelocationStatus[];
  hideBadge?: boolean;
};

const filters: Filter[] = [
  {
    label: "Active",
    statuses: [RelocationStatus.Ready],
  },
  {
    label: "Paused",
    statuses: [RelocationStatus.Paused],
  },
  {
    label: "Sold out",
    statuses: [RelocationStatus.SoldOut],
  },
  {
    label: "Expired",
    statuses: [RelocationStatus.Expired],
  },

  {
    label: "Draft",
    statuses: [RelocationStatus.Draft],
  },
  {
    label: "Archived",
    statuses: [RelocationStatus.Archived],
    hideBadge: true,
  },
];

export function RelocationTableScreen() {
  const { search } = useMatch({
    from: "/_app/relocations",
  });

  const navigate = useNavigate({
    from: "/relocations/",
  });

  return (
    <FullPageScreen>
      <Header />
      <RelocationTable
        id="relocations"
        statuses={search?.relocationStatus}
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/relocations",
            search: (old) => {
              return {
                ...old,
                relocationStatus: newStatuses,
              };
            },
          });
        }}
      />
    </FullPageScreen>
  );
}

function Header() {
  const { open } = useDialog(RelocationCreateDialog);
  const { supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(relocationStatusCountsQuery, {
    supplierId: supplierId ?? undefined,
  });

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Relocations
        </h3>
        <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({});
            }}
          >
            Add relocation
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          >
            {filters.map((tab, idx) => (
              <option key={idx}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {filters.map((tab, idx) => {
              const stats = data?.relocationStatusCounts.filter((s) =>
                tab.statuses.includes(s.status),
              );

              const count = stats?.reduce((acc, stat) => acc + stat.count, 0);

              return (
                <Link
                  key={idx}
                  to={"/relocations"}
                  preload="intent"
                  search={(oldSearch) => {
                    return {
                      ...oldSearch,
                      relocationStatus: tab.statuses,
                    };
                  }}
                  activeProps={() => {
                    return { className: "border-indigo-500 text-indigo-600" };
                  }}
                  inactiveProps={() => {
                    return {
                      className:
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    };
                  }}
                  className={cn(
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                  )}
                >
                  {tab.label}

                  {count && !tab.hideBadge ? (
                    <Badge color="indigo" className="ml-2">
                      {count}
                    </Badge>
                  ) : null}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
