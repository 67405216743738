import { CellContext } from "@tanstack/react-table";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { SupplierTripsTable } from "@/app/Suppliers/Components/SupplierTripsTable";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { OfficeTable } from "@/app/Offices/Components/OfficeTable";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";

export function SupplierOfficeScreen() {
  const record = useSupplierRecord();
  const { open } = useDialog(OfficeCreateDialog);

  return (
    <>
      <OfficeTable
        id={"supplier-offices"}
        queryVariables={{
          supplier_id: record.id,
        }}
        rightButtons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialValues: {
                  supplier: {
                    connect: record.id,
                  },
                },
              });
            }}
          >
            Add office
          </Button>
        }
      />

      <RecordScreenCard title={"Trips"}>
        <SupplierTripsTable id="supplier-offices" supplierId={record.id} />
      </RecordScreenCard>
    </>
  );
}

function AddressCell({ row }: CellContext<SupplierOfficeListItem, any>) {
  const address = row.original.address;

  return (
    <div>
      <p>
        {address.address_line_2} {address.address_line_1 ?? "--"}
      </p>
      <p className="text-xs text-gray-500">
        {address.postcode} {address.city} {address.country}
      </p>
    </div>
  );
}
