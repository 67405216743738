import { bookingCardMessages } from "@/app/Bookings/Messages/bookingCardMessages";
import { vehicleMessages } from "@/app/Vehicles/messages/vehicleMessages";
import { relocationMessages } from "@/app/Relocations/messages/relocationMessages";

export const messages = {
  booking: {
    cards: bookingCardMessages,
  },
  vehicle: vehicleMessages,
  relocation: relocationMessages,
};
