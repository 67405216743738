import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { useCityRecord } from "@/app/Cities/Cities/Hooks/useCityRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import {
  cityDetailRoute,
  cityTripRoute,
} from "@/app/Cities/Cities/Routes/cityRoutes";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { ArrowLeftRightIcon } from "lucide-react";

export function CityRecordScreen() {
  const city = useCityRecord();

  return (
    <RecordScreen
      title={city.name}
      subtitle={`Created ${formatDateTimeSinceNow(city.created_at)}`}
      tabs={[
        {
          name: "Details",
          to: cityDetailRoute.to,
          Icon: DocumentTextIcon,
        },
        {
          name: "Trips",
          to: cityTripRoute.to,
          Icon: ArrowLeftRightIcon,
        },
      ]}
    />
  );
}
