import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { GenericField } from "@/lib/Components/Form/Fields/GenericField";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { ClosedDaysSchema } from "@/app/Suppliers/Utils/mapClosedDays";
import dayjs from "dayjs";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useField } from "formik";
import { formatDate } from "@/lib/Formatters/formatDate";

export function ClosedDatesForm({ emptyState }: { emptyState: ReactNode }) {
  const { isEditing, values } = useForm<{ closed_dates: ClosedDaysSchema[] }>();

  const repeatingDates = values.closed_dates.filter((date) => date.repeating);
  const nonRepeatingDates = values.closed_dates.filter(
    (date) => !date.repeating,
  );

  if (!isEditing) {
    if (values.closed_dates.length === 0) {
      return <div className="col-span-full">{emptyState}</div>;
    }

    return (
      <DescriptionList
        className="col-span-full"
        items={[
          ...(repeatingDates.length > 0
            ? [
                {
                  key: "Repeating dates",
                  value: (
                    <ul>
                      {repeatingDates.map((date) => {
                        return (
                          <li key={date.date}>
                            <span className="font-semibold">
                              {dayjs(date.date).format("DD MMM")}
                            </span>{" "}
                            (repeating)
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              ]
            : []),
          ...(nonRepeatingDates.length > 0
            ? [
                {
                  key: "One off dates",
                  value: (
                    <ul>
                      {nonRepeatingDates.map((date) => {
                        return (
                          <li key={date.date}>
                            <span className="font-semibold">
                              {dayjs(date.date).format("DD MMM YY")}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              ]
            : []),
        ]}
      />
    );
  }

  return (
    <>
      <GenericFieldArray
        fieldArrayKey={"closed_dates"}
        label="Closed dates"
        newLineItemValues={""}
        Icon={CalendarDaysIcon}
        lineItemLabel={"closed date"}
      >
        {(item, index) => {
          return (
            <>
              <OpenHoursDatePicker
                name={`closed_dates.${index}.date`}
                className="col-span-4"
              />
              <SwitchInput
                name={`closed_dates.${index}.repeating`}
                label="Repeating"
              />
            </>
          );
        }}
      </GenericFieldArray>
    </>
  );
}

function OpenHoursDatePicker({
  name,
  className,
}: {
  name: string;
  className?: string;
}) {
  const [{ value }, {}, { setValue }] = useField(name);
  const date = value ? dayjs(value).toDate() : null;

  return (
    <GenericField
      name={name}
      label={"Date"}
      className={cn("col-span-3", className)}
      viewNode={formatDate(date)}
    >
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal w-full",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={date ?? undefined}
            numberOfMonths={2}
            onSelect={(date) => {
              setValue(dayjs(date).format("YYYY-MM-DD"));
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </GenericField>
  );
}
