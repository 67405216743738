import {
  ActionGroupButton,
  ActionGroupButtons,
} from "../../Actions/ActionGroupButtons";
import { ReactNode, useState } from "react";
import { Button } from "@/components/ui/button";
import { Copy, CopyCheckIcon } from "lucide-react";
import { toast } from "sonner";

type RecordScreenTitleCardProps = {
  title: string;
  subtitle: ReactNode;
  badges: ReactNode;
  actionGroups: ActionGroupButton[];
};
export function RecordScreenTitleCard({
  badges,
  title,
  actionGroups,
  subtitle,
}: RecordScreenTitleCardProps) {
  const [clicked, setClicked] = useState(false);

  return (
    <div className="space-y-6 xl:flex xl:space-y-0">
      <div className="flex flex-grow space-x-4">
        <div className="">
          <h1 className="text-lg font-semibold leading-none tracking-tight group">
            {title}
            <Button
              size="icon"
              variant="outline"
              className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100 ml-2"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(title);
                  setClicked(true);
                  toast.success("Success", {
                    description: `'${title}' to clipboard`,
                  });
                  setTimeout(() => {
                    setClicked(false);
                  }, 1000);
                } catch (e: any) {
                  console.log(e);
                  alert("Failed to copy ID");
                } finally {
                }
              }}
            >
              {clicked ? (
                <CopyCheckIcon className="h-3 w-3" />
              ) : (
                <Copy className="h-3 w-3" />
              )}
              <span className="sr-only">Copy ID</span>
            </Button>
          </h1>
          <p className="max-w-2xl text-sm text-gray-500">{subtitle}</p>
          <p className="mt-1 space-x-2">{badges}</p>
        </div>
      </div>
      <ActionGroupButtons buttons={actionGroups} />
    </div>
  );
}
