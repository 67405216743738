export function parseParams(params?: Record<string, any>): Record<string, any> {
  if (!params) return {};

  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      let parsedVal = value;

      if (Array.isArray(value) && value.length === 0) {
        parsedVal = undefined;
      }

      return [key, parsedVal];
    }),
  );
}
