import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import {
  ActivityIcon,
  ArrowLeftRightIcon,
  Clock,
  DollarSignIcon,
} from "lucide-react";
import { OfficeIcon } from "@/app/Offices/Components/OfficeIcon";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useSupplierOfficeActions } from "@/app/Offices/Hooks/useSupplierOfficeActions";
import { Badge } from "@/components/catalyst/badge";

export function OfficeRecordScreen() {
  const office = useOfficeRecord();
  const getActions = useSupplierOfficeActions();

  return (
    <RecordScreen
      title={office.name}
      subtitle={`Created ${formatDateTimeSinceNow(office.created_at)}`}
      titleIcon={<OfficeIcon className="w-10 h-10" model={office} />}
      badges={
        <>
          {office.archived_at ? <Badge color="purple">archived</Badge> : null}
        </>
      }
      actionsGroups={getActions(office)}
      tabs={[
        {
          name: "Details",
          to: "/offices/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Open hours",
          to: "/offices/$id/open-hours",
          Icon: Clock,
        },
        {
          name: "Relocations",
          to: "/offices/$id/relocations",
          Icon: ArrowLeftRightIcon,
        },
        {
          name: "Bookings",
          to: "/offices/$id/bookings",
          Icon: DollarSignIcon,
        },
        {
          name: "Activity",
          to: "/offices/$id/activity",
          Icon: ActivityIcon,
        },
      ]}
    />
  );
}
