import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import {
  QueryRelocationsOrderByColumn,
  QueryRelocationsWhereColumn,
  RelocationStatus,
  SortOrder,
} from "@/gql/graphql";
import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";

export function OfficeRelocationsScreen() {
  const record = useOfficeRecord();

  return (
    <>
      <RecordScreenCard title={"Departing relocations"}>
        <RelocationTable
          id="office-departing-relocations"
          defaultEnabledStatuses={[
            RelocationStatus.Ready,
            RelocationStatus.Draft,
            RelocationStatus.SoldOut,
            RelocationStatus.Paused,
          ]}
          queryVariables={{
            orderBy: [
              {
                column: QueryRelocationsOrderByColumn.AvailableFromDate,
                order: SortOrder.Asc,
              },
            ],
            where: {
              column: QueryRelocationsWhereColumn.DepartureOfficeId,
              value: record.id,
            },
          }}
        />
      </RecordScreenCard>

      <RecordScreenCard title={"Arriving relocations"}>
        <RelocationTable
          id="office-arriving-relocations"
          defaultEnabledStatuses={[
            RelocationStatus.Ready,
            RelocationStatus.Draft,
            RelocationStatus.SoldOut,
            RelocationStatus.Paused,
          ]}
          queryVariables={{
            orderBy: [
              {
                column: QueryRelocationsOrderByColumn.AvailableFromDate,
                order: SortOrder.Asc,
              },
            ],
            where: {
              column: QueryRelocationsWhereColumn.DeliveryOfficeId,
              value: record.id,
            },
          }}
        />
      </RecordScreenCard>
    </>
  );
}
