import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { BookingStatus } from "@/gql/graphql";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ArrowLeftRightIcon, FilterIcon } from "lucide-react";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import {
  BookingListSearchParams,
  bookingRoute,
  bookingsRoute,
} from "@/app/Bookings/Routes/bookingRoutes";
import { useState } from "react";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  BookingListItem,
  bookingListQuery,
} from "@/app/Bookings/GraphQL/bookingListQuery";
import { getBookingListQueryVariables } from "@/app/Bookings/Utils/getBookingListQueryVariables";
import { keepPreviousData } from "@tanstack/react-query";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useDeferredRouting } from "@/lib/TanstackRouter/useDeferredRouting";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

export function BookingListScreen() {
  const { isAdmin } = useTenant();
  const searchParams = useSearch({
    from: "/_app/bookings",
  });
  const [search, setSearch] = useState(searchParams.bookingSearch ?? "");

  const queryVariables = getBookingListQueryVariables({
    ...searchParams,
    bookingSearch: search,
  });

  useDeferredRouting<BookingListSearchParams>({
    from: bookingRoute,
    searchParams: {
      bookingSearch: search || undefined,
    },
  });

  const { data, fetchNextPage, isFetching, isFetchingNextPage } =
    useInfiniteGqlQuery(bookingListQuery, queryVariables, {
      placeholderData: keepPreviousData,
      initialPageParam: {
        page: 1,
      },
      getNextPageParam: (lastPage) => {
        return lastPage.bookings.paginatorInfo.hasMorePages
          ? { page: lastPage.bookings.paginatorInfo.currentPage + 1 }
          : undefined;
      },
    });

  const items = data?.pages.flatMap((item) => item.bookings.data) ?? [];
  const totalCount = data?.pages.at(0)?.bookings.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      items={items}
      totalCount={totalCount}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      createRoute={isAdmin ? "/bookings/create" : undefined}
      listRoute={"/bookings"}
      onClearFilters={() => {
        setSearch("");
      }}
      filters={
        <>
          <div className="flex items-center">Sorted by date</div>
          <div>
            <BookingStatusFilter />
          </div>
        </>
      }
      search={search}
      onSearchChange={setSearch}
      itemNode={(item) => <BookingItem model={item} />}
      linkProps={(item) => ({
        to: bookingRoute.to,
        params: {
          bookingId: item.id,
        },
      })}
    />
  );
}

function BookingItem({ model }: { model: BookingListItem }) {
  const { t } = useTranslation("booking");
  const { hasSupplier } = useGlobalSupplier();

  return (
    <div className="flex space-x-2 justify-between">
      <div className="space-y-1 truncate">
        <p className="truncate text-sm font-medium text-gray-900">
          <span>{model.reference}</span>
        </p>
        {!hasSupplier ? (
          <p className="flex items-center truncate text-xs text-gray-500">
            <BuildingOfficeIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
            {`${model.relocation.supplier.name}`}
          </p>
        ) : null}

        <p className="flex items-center truncate text-xs text-gray-500">
          <UserIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${model.name}`}
        </p>
        <p className="flex items-center truncate text-xs text-gray-500">
          <ArrowLeftRightIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${model.relocation.departureOffice.name} - ${model.relocation.deliveryOffice.name}`}
        </p>
        <p className="flex items-center truncate text-xs text-gray-500">
          <CalendarDaysIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${formatDate(model.depart_at)} - ${formatDate(model.deliver_at)}`}
        </p>
        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>

      <div className="flex-shrink-0 min-w-0">
        <Badge
          color={bookingStatusColorMap[model.status]}
          className="text-xs capitalize"
        >
          {t(`statusShortLabel.${model.status}`)}
        </Badge>
      </div>
    </div>
  );
}

export function BookingStatusFilter() {
  const { bookingStatus } = bookingsRoute.useSearch();
  const navigate = useNavigate({ from: bookingsRoute.to });
  const { t } = useTranslation("booking");

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <FilterIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Status</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {Object.values(BookingStatus).map((value) => {
            return (
              <DropdownMenuCheckboxItem
                onSelect={(e) => {
                  e.preventDefault();
                }}
                key={value}
                checked={bookingStatus?.includes(value)}
                onCheckedChange={async (val) => {
                  const newStatuses = bookingStatus ?? [];

                  if (val) {
                    newStatuses.push(value);
                  } else {
                    const index = newStatuses.indexOf(value);
                    newStatuses.splice(index, 1);
                  }

                  await navigate({
                    resetScroll: true,
                    search: (search) => {
                      return {
                        ...search,
                        bookingStatus:
                          newStatuses.length > 0 ? newStatuses : undefined,
                      };
                    },
                  });
                }}
              >
                {t(`status.${value}`)}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
