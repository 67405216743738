import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@tanstack/react-router";
import { ActivityIcon } from "lucide-react";
import {
  invoiceActivityRoute,
  invoiceDetailRoute,
} from "@/app/Invoices/Routes/invoiceRoutes";
import { contactRoute } from "@/app/Contacts/Routes/contactRoute";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useInvoiceActions } from "@/app/Invoices/Hooks/useInvoiceActions";
import { Badge } from "@/components/catalyst/badge";
import { useTranslation } from "react-i18next";
import { invoiceStatusIntentMap } from "@/app/Invoices/Utils/invoiceStatusIntentMap";

export function InvoiceRecordScreen() {
  const invoice = useInvoiceRecord();
  const getActions = useInvoiceActions();
  const { t } = useTranslation("invoice");

  return (
    <RecordScreen
      title={invoice.reference}
      actionsGroups={getActions(invoice)}
      badges={
        <Badge color={invoiceStatusIntentMap[invoice.status]}>
          {t(`status.${invoice.status}`)}
        </Badge>
      }
      subtitle={`Created ${formatDateTimeSinceNow(invoice.created_at)}`}
      banner={<BillingEmailMissingBanner />}
      tabs={[
        {
          name: "Details",
          to: invoiceDetailRoute.to,
          Icon: DocumentTextIcon,
        },
        {
          name: "Activity",
          to: invoiceActivityRoute.to,
          Icon: ActivityIcon,
        },
      ]}
      titleIcon={<DocumentTextIcon className="w-10 h-10" />}
    />
  );
}

function BillingEmailMissingBanner() {
  const record = useInvoiceRecord();

  if (record.payee.billingEmail) {
    return null;
  }

  return (
    <WarningBanner>
      A billing email is missing for {record.payee.name}.{" "}
      <Link
        to={contactRoute.to}
        params={{
          id: record.payee.id,
        }}
        className="underline"
      >
        fix now
      </Link>
    </WarningBanner>
  );
}
