import { CellContext } from "@tanstack/react-table";
import {
  QueryWaitlistsOrderColumn,
  QueryWaitlistsWhereColumn,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { UserProfilePicture } from "@/app/Users/Components/UserProfilePicture";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { UserActivityDialog } from "@/app/Users/Components/UserActivityDialog";
import { PhoneIcon } from "lucide-react";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  WaitlistListItem,
  waitlistListQuery,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import { formatDate } from "@/lib/Formatters/formatDate";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { useWaitlistActions } from "@/app/Waitlists/Actions/useWaitlistActions";

export function WaitlistTable() {
  return (
    <>
      <div className="py-6">
        <GenericForm
          initialValues={{
            departure_city: null!,
            delivery_city: null!,
            with_matches: "",
            with_phone_number: "",
            earliest_departure_date: null!,
            latest_departure_date: null!,
          }}
          onSubmit={() => {
            return;
          }}
        >
          <CityInput name="departure_city" label="Pickup" clearable />
          <CityInput name="delivery_city" label="Dropoff" clearable />
          <DateInput label="Earliest pickup" name="earliest_departure_date" />
          <DateInput label="Latest dropoff" name="latest_departure_date" />

          <SwitchInput label="With matches" name="with_matches" />
          <SwitchInput label="With phone number" name="with_phone_number" />

          <div className="col-span-full">
            <WaitlistTableComponent id="waitlists" />
          </div>
        </GenericForm>
      </div>
    </>
  );
}

type WaitlistColumnId =
  | "customer"
  | "trip"
  | "dates"
  | "matches"
  | "last_called"
  | "created";
function WaitlistTableComponent({ id }: { id: TableId }) {
  const getActions = useWaitlistActions();
  const { values } = useForm<{
    departure_city: string | null;
    delivery_city: string | null;
    earliest_departure_date: string | null;
    latest_departure_date: string | null;
    with_matches: string | null;
    with_phone_number: string | null;
  }>();

  const columns: DataTableColDef<WaitlistListItem, any, WaitlistColumnId>[] = [
    {
      id: "customer",
      header: "Customer",
      cell: CustomerCell,
    },
    {
      id: "trip",
      header: "Trip",
      accessorFn: (row) =>
        `${row.departureCity.name} to ${row.deliveryCity.name}`,
    },
    {
      id: "dates",
      header: "Dates",
      accessorFn: (row) =>
        `${formatDate(row.earliest_departure_date)} - ${formatDate(
          row.latest_departure_date,
        )}`,
    },
    {
      id: "matches",
      header: "Matches",
      cell: MatchesCell,
    },
    {
      id: "last_called",
      header: "Last called",
      cell: LastCalledCell,
    },
    {
      id: "created",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <DataTable<
      ResultOf<typeof waitlistListQuery>,
      VariablesOf<typeof waitlistListQuery>,
      WaitlistColumnId,
      WaitlistListItem,
      any
    >
      getActions={getActions}
      id={id}
      document={waitlistListQuery}
      columns={columns}
      accessor={(data) => data.waitlists}
      getQueryVariables={({ search, pagination, sorting }) => {
        return {
          search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          order: search
            ? undefined
            : [
                {
                  column: QueryWaitlistsOrderColumn.CreatedAt,
                  order: SortOrder.Desc,
                },
              ],
          where: search
            ? undefined
            : {
                AND: [
                  ...(values.departure_city
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.DepartureCityId,
                          operator: SqlOperator.Eq,
                          value: values.departure_city,
                        },
                      ]
                    : []),
                  ...(values.delivery_city
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.DeliveryCityId,
                          operator: SqlOperator.Eq,
                          value: values.delivery_city,
                        },
                      ]
                    : []),
                  ...(values.earliest_departure_date
                    ? [
                        {
                          column:
                            QueryWaitlistsWhereColumn.EarliestDepartureDate,
                          operator: SqlOperator.Gte,
                          value: values.earliest_departure_date,
                        },
                      ]
                    : []),
                  ...(values.latest_departure_date
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.LatestDepartureDate,
                          operator: SqlOperator.Lte,
                          value: values.latest_departure_date,
                        },
                      ]
                    : []),
                ],
              },
          ...(values.with_matches && !search ? { hasMatches: true } : {}),
          ...(values.with_phone_number && !search
            ? { hasPhoneNumber: true }
            : {}),
        };
      }}
    />
  );
}

export function MatchesCell({ row }: CellContext<any, any>) {
  if (row.original.matches === 0) {
    return <div>--</div>;
  }

  return (
    <div>
      <a
        href={row.original.relocationLink}
        target="_blank"
        className="underline text-blue-500 flex space-x-2 items-center"
        rel="noreferrer"
      >
        <span>{row.original.matches}</span>
        <ArrowTopRightOnSquareIcon className="w-4 h-4" />
      </a>
    </div>
  );
}

function LastCalledCell({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;

  const { open } = useDialog(UserActivityDialog);

  return (
    <div className="flex items-center space-x-2">
      <span>
        {formatDateTimeSinceNow(waitlist.user.latestCallActivity?.created_at)}
      </span>
      <TextButton
        intent="primary"
        Icon={PhoneIcon}
        onClick={() => {
          open({
            userId: waitlist.user.id,
          });
        }}
      >
        Log call
      </TextButton>
    </div>
  );
}

function CustomerCell({ row }: CellContext<WaitlistListItem, any>) {
  const user = row.original.user;

  return (
    <div className="flex space-x-3 items-center">
      <UserProfilePicture model={user} />
      <div>
        <p>{user.name?.trim() || "--"}</p>
        <p>
          {user.primary_phone ? (
            <a
              className="underline text-blue-500"
              href={`tel:${user.primary_phone}`}
            >
              {user.primary_phone}
            </a>
          ) : (
            "--"
          )}
        </p>
        <p>
          {user.primary_email ? (
            <a
              className="underline text-blue-500"
              href={`mailto:${user.primary_email}`}
            >
              {user.primary_email}
            </a>
          ) : (
            "--"
          )}
        </p>
      </div>
    </div>
  );
}
