import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { OrganisationLogo } from "@/app/Organisations/Components/OrganisationLogo";
import { useOrganisationRecord } from "@/app/Organisations/Hooks/useOrganisationRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";

export function OrganisationRecordScreen() {
  const organisation = useOrganisationRecord();

  return (
    <RecordScreen
      title={organisation.name}
      subtitle={`Created ${formatDateTimeSinceNow(organisation.created_at)}`}
      titleIcon={<OrganisationLogo organisation={organisation} />}
      tabs={[]}
    />
  );
}
