import { RefObject, useState } from "react";
import { GoogleMapsPlace, Region } from "@/gql/graphql";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { Combobox } from "@/lib/Components/Form/Components/Combobox";
import { cn } from "@/lib/utils";
import { useFetchData, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  googleMapsAddressLookupQuery,
  googleMapsPlaceDetailsQuery,
} from "@/app/Address/GraphQL/googleMapsAddressLookupQuery";
import { useAuth } from "@clerk/clerk-react";
import { CityInputRes } from "@/app/Cities/Cities/GraphQL/cityInputQuery";
import { useThrottle } from "@/lib/Utils/useThrottle";

type GoogleMapsAutoCompleteInputProps = {
  prefix?: string;
  placeholder?: string;
  className?: string;
  onSelect?: () => void;
  initialSearch?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  city?: CityInputRes;
};

export function GoogleMapsAutocomplete({
  placeholder = "Search...",
  prefix = "",
  initialSearch = "",
  onSelect,
  buttonRef,
  city,
  className,
}: GoogleMapsAutoCompleteInputProps) {
  const [search, setSearch] = useState(initialSearch);
  const throttledValue = useThrottle(search, 600);
  const { sessionId } = useAuth();

  const { setFieldValue } = useForm();
  const fetcher = useFetchData(googleMapsPlaceDetailsQuery);

  const { data, isFetching } = useGqlQuery(
    googleMapsAddressLookupQuery,
    {
      input: {
        search: throttledValue,
        state: city?.state,
        sessiontoken: sessionId,
      },
    },
    {
      placeholderData: (previousData: any) => previousData,
    },
  );

  const items = data?.googleMapsAutocomplete ?? [];
  return (
    <Combobox
      isLoading={isFetching}
      search={search}
      onSearchChange={setSearch}
      placeholder={placeholder}
      items={items}
      selected={null}
      buttonProps={{
        className: cn("w-full hover:bg-gray-50", className),
      }}
      commandProps={{
        className: "w-full",
      }}
      onChange={async (item) => {
        const res = await fetcher({
          placeId: item.place_id,
        });

        let address1 = "";
        let postcode = "";
        let city = "";
        let state = "";
        let country = "";
        let addressLine2 = "";

        for (const component of res.googleMapsPlace
          .address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];
          switch (componentType) {
            case "street_number": {
              address1 = `${component.long_name} ${address1}`;
              break;
            }

            case "route": {
              address1 += component.short_name;
              break;
            }

            case "postal_code": {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case "postal_code_suffix": {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }

            case "locality":
              city = component.long_name;
              break;

            case "administrative_area_level_1": {
              state = component.long_name;
              break;
            }

            case "country":
              country = component.long_name;
              break;

            case "subpremise":
              addressLine2 = component.long_name;
              break;
          }
        }

        await setFieldValue(prefix, {
          name: item.description,
          place_id: item.place_id,
          address_line_1: address1,
          address_line_2: addressLine2,
          city,
          state,
          postcode,
          country,
          lat: res.googleMapsPlace.geometry.location.lat,
          lng: res.googleMapsPlace.geometry.location.lng,
        });

        onSelect?.();
      }}
      buttonRef={buttonRef}
      getListItemNode={(item) => <Node model={item} />}
      getKey={(item) => item.place_id}
      getInputNode={(item) => item.structured_formatting.main_text}
    />
  );
}

function Node({ model }: { model: GoogleMapsPlace }) {
  return (
    <div>
      <p>{model.structured_formatting.main_text}</p>
      <p className="text-gray-500 text-xs">
        {model.structured_formatting.secondary_text}
      </p>
    </div>
  );
}
