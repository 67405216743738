import {
  CreateRelocationInput,
  Currency,
  DepositRefundedAt,
  HireUnitType,
  HoldingDepositRefundedBy,
  Measurement,
  Region,
} from "@/gql/graphql";
import dayjs from "dayjs";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function getDefaultRelocationValues({
  supplier,
}: {
  supplier?: SupplierRecord;
}): CreateRelocationInput {
  return {
    count: 1,
    supplier: {
      connect: supplier?.id ?? null!,
    },
    supplierInsuranceOption: {
      connect: supplier?.defaultInsuranceOption?.id ?? null!,
    },
    vehicle: {
      connect: null!,
    },
    departureOffice: {
      connect: null!,
    },
    deliveryOffice: {
      connect: null!,
    },

    available_from_date: dayjs().format("YYYY-MM-DD"),
    available_to_date: null!,

    booking_fee_amount: supplier?.booking_fee_amount ?? 0,
    minimum_hire_units: null!,
    hire_unit_rate: 0,
    hire_unit_type: supplier?.hire_unit_type ?? HireUnitType.Day,
    hire_units_allowed: null!,

    distance_allowed: null!,

    extra_distance_allowed_per_extra_hire_unit:
      supplier?.extra_distance_allowed_per_extra_hire_unit ?? 0,
    excess_distance_rate: supplier?.excess_distance_rate ?? 0,
    extra_hire_unit_rate: 0,

    extra_hire_units_allowed: supplier?.default_extra_hire_units_allowed ?? 0,
    extra_hire_unit_deposit_rate: 0,

    holding_deposit_amount: supplier?.is_holding_deposit_required
      ? supplier.holding_deposit_amount
      : 0,
    holding_deposit_refunded_by:
      supplier?.holding_deposit_refunded_by ??
      HoldingDepositRefundedBy.Supplier,
    holding_deposit_refunded_at:
      supplier?.holding_deposit_refunded_at ?? DepositRefundedAt.Departure,
    charge_per_move_amount: supplier?.charge_per_move_amount ?? 0,

    currency: supplier?.currency ?? Currency.Aud,
    region: supplier?.region ?? Region.Au,

    measurement: supplier?.measurement ?? Measurement.Metric,
    fixed_latest_departure_date: null!,
    extra_hire_unit_supplier_net_rate: null!,

    is_ferry_required: false,

    inclusions: {
      upsert: [],
      delete: [],
    },

    line: {
      upsert: {
        supplier_id: supplier?.id ?? null,
        reference: "",
      },
    },
  };
}
