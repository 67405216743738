import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { UpdateContactInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { ContactDetailsForm } from "@/app/Contacts/Forms/ContactDetailsForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { ContactEmailsForm } from "@/app/Contacts/Forms/ContactEmailsForm";
import { contactValidation } from "@/app/Contacts/Forms/contactValidation";
import { ContactPhonesForm } from "@/app/Contacts/Forms/ContactPhonesForm";
import { AddressForm } from "@/app/Address/Form/AddressForm";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { updateContactMutation } from "@/app/Contacts/GraphQL/contactMutations";
import { contactRecordQuery } from "../GraphQL/contactRecordQuery";

type ContactEditDialogProps = {
  contactId: string;
};
export function ContactEditDialog({ contactId }: ContactEditDialogProps) {
  const { mutateAsync } = useGqlMutation(updateContactMutation);
  const { close } = useDialogState();
  const { data, isLoading } = useGqlQuery(contactRecordQuery, {
    id: contactId,
  });

  const contact = data?.contact;

  return (
    <GenericDialog title={`Update contact`} isLoading={isLoading}>
      {contact ? (
        <GenericForm<UpdateContactInput>
          validationSchema={contactValidation}
          initialValues={{
            name: contact.name,
            company_number: contact.company_number,
            legal_name: contact.legal_name,
            purchase_order_number: contact.purchase_order_number,

            emails: {
              upsert: contact.emails.map((email) => ({
                id: email.id,
                email: email.email,
                is_billing: email.is_billing,
                is_primary: email.is_primary,
              })),
              delete: [],
            },
            phones: {
              upsert: contact.phones.map((phone) => ({
                id: phone.id,
                phone: phone.phone,
                is_billing: phone.is_billing,
                is_primary: phone.is_primary,
              })),
              delete: [],
            },
            billingAddress: {
              upsert: {
                id: contact.billingAddress?.id,
                city: contact.billingAddress?.city,
                country: contact.billingAddress?.country,
                postcode: contact.billingAddress?.postcode,
                name: contact.billingAddress?.name,
                lat: contact.billingAddress?.lat,
                lng: contact.billingAddress?.lng,
                place_id: contact.billingAddress?.place_id,
                address_line_1: contact.billingAddress?.address_line_1,
                address_line_2: contact.billingAddress?.address_line_2,
                state: contact.billingAddress?.state,
              },
            },
          }}
          onSubmit={async (values) => {
            await mutateAsync({
              id: contact.id,
              input: values,
            });

            close();
          }}
        >
          <ContactDetailsForm contact={contact} />
          <ContactEmailsForm label="Emails" />
          <FormDivider />
          <ContactPhonesForm label="Phones" />
          <FormDivider />
          <AddressForm prefix="billingAddress.upsert" />
          <DialogSubmitButton text={"Add contact"} />
        </GenericForm>
      ) : null}
    </GenericDialog>
  );
}
