import { EmptyRecordScreen } from "@/lib/Components/Screens/EmptyRecordScreen";
import { PlusIcon } from "@heroicons/react/24/outline";
import { supplierCreateRoute } from "@/app/Suppliers/Routes/supplierRoutes";

export function SupplierEmptyScreen() {
  return (
    <EmptyRecordScreen
      title="Select a supplier"
      flair="Suppliers"
      description="Manage your business defaults through your supplier records"
      items={[
        {
          name: "Create a supplier",
          description: "List your relocations under a new supplier",
          Icon: PlusIcon,
          toOptions: {
            to: supplierCreateRoute.to,
            search: (search) => search,
          },
        },
      ]}
    />
  );
}
