import { Currency, HireUnitType, Measurement } from "@/gql/graphql";

export const currencyDecimals: {
  [key in Currency]: number;
} = {
  [Currency.Aud]: 2,
  [Currency.Cad]: 2,
  [Currency.Eur]: 2,
  [Currency.Fjd]: 2,
  [Currency.Gbp]: 2,
  [Currency.Idr]: 2,
  [Currency.Nzd]: 2,
  [Currency.Pgk]: 2,
  [Currency.Sbd]: 2,
  [Currency.Usd]: 2,
  [Currency.Zar]: 2,
  [Currency.Vuv]: 0,
  [Currency.Xpf]: 0,
  [Currency.Jpy]: 0,
};

export function changeCurrencyToDollars(cents: number, currency: Currency) {
  const MULTIPLIER = 10 ** currencyDecimals[currency];
  return cents / MULTIPLIER;
}

export function formatCurrency(
  cents: number | null | undefined,
  currency: Currency,
  {
    showZero = false,
    allowNegative = true,
    emptyValue = "--",
  }: { showZero?: boolean; allowNegative?: boolean; emptyValue?: string } = {},
) {
  if (
    typeof cents === "undefined" ||
    cents === null ||
    (cents === 0 && !showZero) ||
    (!allowNegative && cents < 0)
  ) {
    return emptyValue;
  }

  const decimals = currencyDecimals[currency];
  const MULTIPLIER = 10 ** decimals;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(cents / MULTIPLIER);
}

export function formatDistance(
  units: number | null | undefined,
  measurement: Measurement,
) {
  if (typeof units === "undefined") {
    return "--";
  }

  if (units === null) {
    return "Unlimited";
  }

  return new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: measurement === Measurement.Imperial ? "mile" : "kilometer",
  }).format(units);
}

export function formatDuration(
  hireUnits: number,
  extraHireUnits: number,
  hireUnitType: HireUnitType,
) {
  let hireUnitLabel = "x 24hrs";
  const totalUnits = hireUnits + extraHireUnits;

  if (totalUnits <= 1) {
    switch (hireUnitType) {
      case HireUnitType.Day:
        hireUnitLabel = "day";
        break;
      case HireUnitType.Night:
        hireUnitLabel = "night";
        break;
    }
  } else {
    switch (hireUnitType) {
      case HireUnitType.Day:
        hireUnitLabel = "days";
        break;
      case HireUnitType.Night:
        hireUnitLabel = "nights";
        break;
    }
  }

  if (extraHireUnits === 0) {
    return `${hireUnits} ${hireUnitLabel}`;
  }

  return `${hireUnits} + ${extraHireUnits} ${hireUnitLabel}`;
}

export function getCurrencySymbol(currency: Currency) {
  const parts = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).formatToParts();

  return parts.find((p) => p.type === "currency")!.value;
}
