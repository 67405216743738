import { GoogleMap, MarkerF } from "@react-google-maps/api";
import {
  useGeoLocation,
  useGeoPermissions,
} from "@/lib/GoogleMaps/Hooks/useGeoPermissions";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { cn } from "@/lib/utils";
import { GoogleMapsLoader } from "@/lib/GoogleMaps/Components/GoogleMapsLoader";

type Position = {
  lat: number;
  lng: number;
};

type MapProps = {
  position?: Position;
  className?: string;
  height: number;
};
export function Map({ position, className, height }: MapProps) {
  const { data: permissionState, isLoading } = useGeoPermissions();
  const { data: location, isLoading: locationLoading } = useGeoLocation();

  return (
    <div
      className={cn(
        "w-full flex items-center justify-center bg-gray-50",
        className,
      )}
      style={{
        height: `${height}px`,
      }}
    >
      <GoogleMapsLoader>
        {isLoading || (permissionState === "granted" && locationLoading) ? (
          <Spinner />
        ) : (
          <GoogleMap
            mapContainerClassName="h-full w-full"
            zoom={13}
            center={
              position?.lat && position?.lng
                ? position
                : {
                    lat: location?.coords.latitude ?? 0,
                    lng: location?.coords.longitude ?? 0,
                  }
            }
          >
            {position && <MarkerF position={position} />}
          </GoogleMap>
        )}
      </GoogleMapsLoader>
    </div>
  );
}
