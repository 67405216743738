import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { Link } from "@tanstack/react-router";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  InvoiceListItem,
  invoiceListQuery,
  InvoiceListQueryVariables,
} from "../GraphQL/invoiceListQuery";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";

import { Badge } from "@/components/catalyst/badge";
import { invoiceStatusIntentMap } from "@/app/Invoices/Utils/invoiceStatusIntentMap";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { useInvoiceActions } from "@/app/Invoices/Hooks/useInvoiceActions";
import {
  InvoiceStatus,
  QueryInvoicesOrderByColumn,
  QueryInvoicesOrderByOrderByClause,
  SortOrder,
} from "@/gql/graphql";
import {
  MultiSelectFilter,
  MultiSelectFilterOption,
} from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { useEffect, useState } from "react";

type InvoiceTableProps = {
  queryVariables?: Partial<InvoiceListQueryVariables>;
  id: TableId;
  hiddenColumns?: InvoiceTableColumnId[];
  defaultEnabledStatuses?: InvoiceStatus[];
  statuses?: InvoiceStatus[];
  onStatusChange?: (status: InvoiceStatus[]) => void;
};

const options: MultiSelectFilterOption<InvoiceStatus>[] = [
  {
    label: "Issued",
    value: InvoiceStatus.Issued,
  },
  {
    label: "Overdue",
    value: InvoiceStatus.Overdue,
  },
  {
    label: "Paid",
    value: InvoiceStatus.Paid,
  },
  {
    label: "Voided",
    value: InvoiceStatus.Voided,
  },
  {
    label: "Draft",
    value: InvoiceStatus.Draft,
  },
];

type InvoiceTableColumnId =
  | "reference"
  | "status"
  | "payee"
  | "amount"
  | "due_date"
  | "created_at"
  | "actions";

export function InvoiceTable({
  queryVariables,
  id,
  hiddenColumns,
  defaultEnabledStatuses = [],
  statuses,
  onStatusChange,
}: InvoiceTableProps) {
  const getActions = useInvoiceActions();

  const [localStatus, setStatus] = useState<InvoiceStatus[]>(
    statuses ?? defaultEnabledStatuses,
  );

  const status = statuses ?? localStatus;

  useEffect(() => {
    onStatusChange?.(localStatus);
  }, [localStatus]);

  const columns: DataTableColDef<InvoiceListItem, any, InvoiceTableColumnId>[] =
    [
      {
        id: "reference",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Invoice" />
        ),
        accessorFn: (row) => row.reference,
        cell: ({ row }) => (
          <Link
            className="text-blue-500 underline"
            to={"/invoices/$id"}
            params={{
              id: row.original.id,
            }}
            search={{}}
          >
            {row.original.reference}
          </Link>
        ),
      },
      {
        id: "status",
        header: "Status",
        accessorFn: (row) => row.status,
        cell: ({ row }) => {
          return (
            <Badge color={invoiceStatusIntentMap[row.original.status]}>
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        id: "payee",
        header: "Payee",
        cell: ({ row }) => (
          <div>
            <p>
              <Link
                className="text-blue-500 underline"
                to={"/contacts/$id"}
                params={{
                  id: row.original.payee.id,
                }}
                search={{}}
              >
                {row.original.payee.name}
              </Link>
            </p>
            <p className="text-gray-500 text-xs">
              {row.original.managedBy?.name}
            </p>
          </div>
        ),
      },
      {
        id: "amount",
        header: "Amount",
        accessorFn: (row) => formatCurrency(row.amount_inc_tax, row.currency),
        cell: DeEmphasisedCell,
      },
      {
        id: "due_date",
        header: "Due Date",
        accessorFn: (row) => formatDate(row.due_date),
        cell: DeEmphasisedCell,
      },
      {
        id: "created_at",
        header: "Created",
        accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
        cell: DeEmphasisedCell,
      },
    ];

  return (
    <DataTable
      id={id}
      getActions={getActions}
      hiddenColumns={hiddenColumns}
      document={invoiceListQuery}
      filters={(table) => (
        <div className="space-x-2">
          <MultiSelectFilter
            label="Status"
            options={options}
            selected={status}
            onChange={(newStatus) => {
              setStatus(newStatus);
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
            }}
          />
        </div>
      )}
      getQueryVariables={({ pagination, search, sorting }) => {
        const orderBy: QueryInvoicesOrderByOrderByClause[] = sorting.map(
          (sort) => {
            return {
              column: QueryInvoicesOrderByColumn.Reference,
              order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
              status: status.length ? status : undefined,
            };
          },
        );

        return {
          search,
          page: pagination.pageIndex,
          first: pagination.pageSize,
          status: status.length ? status : undefined,
          orderBy,
          ...queryVariables,
        };
      }}
      columns={columns}
      accessor={(data) => data.invoices}
    />
  );
}
