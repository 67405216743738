import Dropzone from "react-dropzone";
import { useFileUpload } from "@/lib/FileUpload/Hooks/useFileUpload";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { cn } from "@/lib/utils";

export type Upload = { file: File; temporary_url: string };
type FileUploaderProps = {
  onDrop?: (files: File[]) => void;
  onUpload: (uploads: Upload[]) => Promise<void> | void;
};
export function FileUploader({ onUpload, onDrop }: FileUploaderProps) {
  const { mutateAsync } = useFileUpload();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dropzone
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/webp": [],
        "image/avif": [],
      }}
      onDrop={async (acceptedFiles, fileRejections, event) => {
        try {
          setIsLoading(true);
          onDrop?.(acceptedFiles);
          const files = await mutateAsync(acceptedFiles);
          await onUpload(files);
        } finally {
          setIsLoading(false);
        }
      }}
      disabled={isLoading}
      noClick={isLoading}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={cn(
            "mx-auto flex w-80 cursor-pointer flex-col items-center rounded-md border border-dashed border-gray-200 bg-gray-100/50 p-6",
            {
              "blur-sm pointer-events-none": isLoading,
            },
          )}
        >
          <PhotoIcon className="h-10 w-10 text-blue-500" />
          <input {...getInputProps()} />
          <p className="mt-1">Drag & Drop</p>
          <p>
            or <span className="text-blue-500 hover:underline">browse</span>
          </p>

          <p className="mt-3 text-xs text-gray-500">Supports: JPEG, JPG, PNG</p>
        </div>
      )}
    </Dropzone>
  );
}
