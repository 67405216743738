import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { paidDriverDetailRoute } from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import { usePaidDriverRecord } from "@/app/PaidDrivers/PaidDrivers/Hooks/usePaidDriverRecord";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";

export function PaidDriverRecordScreen() {
  const paidDriver = usePaidDriverRecord();

  return (
    <RecordScreen
      title={paidDriver.user.name ?? "--"}
      subtitle={`Created ${formatDateTimeSinceNow(paidDriver.created_at)}`}
      tabs={[
        {
          name: "Details",
          Icon: DocumentTextIcon,
          to: paidDriverDetailRoute.to,
        },
      ]}
    />
  );
}
