import { appLayoutRoute } from "@/routes";
import { createRoute } from "@tanstack/react-router";
import { BookingRecordScreen } from "@/app/Bookings/Screens/BookingRecordScreen";
import {
  ActivityLogEvent,
  BookingStatus,
  ModelType,
  QueryActivityLogsOrderByColumn,
  QueryActivityLogsWhereColumn,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";
import {
  prefetchInfiniteQuery,
  prefetchQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { z } from "zod";
import { BookingListScreen } from "@/app/Bookings/Screens/BookingListScreen";
import { BookingCreateScreen } from "@/app/Bookings/Screens/BookingCreateScreen";
import { BookingEmptyStateScreen } from "@/app/Bookings/Screens/BookingEmptyStateScreen";
import { SupplierActivityScreen } from "@/app/Suppliers/Screens/SupplierActivityScreen";
import { BookingDetailsScreen } from "@/app/Bookings/Screens/BookingDetailsScreen";
import { BookingRelocationScreen } from "@/app/Bookings/Screens/BookingRelocationScreen";
import { BookingFindRelocationScreen } from "@/app/Bookings/Screens/BookingFindRelocationScreen";
import { BookingChangeRequestScreen } from "@/app/Bookings/Screens/BookingChangeRequestScreen";
import { BookingPaymentsScreen } from "@/app/Bookings/Screens/BookingPaymentsScreen";
import { getBookingListQueryVariables } from "@/app/Bookings/Utils/getBookingListQueryVariables";
import { BookingTaskScreen } from "@/app/Bookings/Screens/BookingTaskScreen";
import { bookingListQuery } from "@/app/Bookings/GraphQL/bookingListQuery";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { activityLogListQuery } from "@/app/ActivityLogs/GraphQL/activityLogListQuery";
import { ErrorScreen } from "@/lib/Components/Screens/ErrorScreen";

const bookingsSearchParams = z.object({
  bookingStatus: z
    .array(z.nativeEnum(BookingStatus))
    .optional()
    .catch(undefined),
  bookingSearch: z.string().optional(),
});
export type BookingListSearchParams = z.infer<typeof bookingsSearchParams>;
export const bookingsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/bookings",
  component: BookingListScreen,
  validateSearch: bookingsSearchParams,
  loaderDeps: ({ search: { bookingStatus, bookingSearch } }) => ({
    bookingStatus,
    bookingSearch,
  }),
  loader: ({ deps, context: { auth } }) => {
    const variables = getBookingListQueryVariables(deps);
    return prefetchInfiniteQuery(bookingListQuery, variables, auth);
  },
});

export const bookingEmptyRoute = createRoute({
  getParentRoute: () => bookingsRoute,
  path: "/",
  component: BookingEmptyStateScreen,
});

export const bookingCreateRoute = createRoute({
  getParentRoute: () => bookingsRoute,
  path: "/create",
  component: BookingCreateScreen,
});

export const bookingRoute = createRoute({
  getParentRoute: () => bookingsRoute,
  path: "/$bookingId",
  component: BookingRecordScreen,
  loader: ({ params: { bookingId: id }, context: { auth } }) => {
    const activityLogVariables = {
      page: 1,
      first: 200,
      where: {
        AND: [
          {
            column: QueryActivityLogsWhereColumn.SubjectId,
            value: id,
          },
          {
            column: QueryActivityLogsWhereColumn.SubjectType,
            value: ModelType.Booking,
          },
          {
            column: QueryActivityLogsWhereColumn.Event,
            operator: SqlOperator.NotIn,
            value: [ActivityLogEvent.Email].map((s) => s.toUpperCase()),
          },
        ],
      },
      orderBy: [
        {
          order: SortOrder.Desc,
          column: QueryActivityLogsOrderByColumn.CreatedAt,
        },
        {
          order: SortOrder.Desc,
          column: QueryActivityLogsOrderByColumn.Id,
        },
      ],
    };

    return Promise.all([
      prefetchQuery(bookingRecordQuery, { id }, auth),
      prefetchQuery(activityLogListQuery, activityLogVariables, auth),
    ]);
  },
  errorComponent: ErrorScreen,
});

export const bookingActivityRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/activity",
  component: SupplierActivityScreen,
});

export const bookingDetailRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/",
  component: BookingDetailsScreen,
});

export const bookingRelocationRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/relocation",
  component: BookingRelocationScreen,
});

export const bookingFindRelocationRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/find-relocation",
  component: BookingFindRelocationScreen,
});

export const bookingChangeRequestsRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/change-requests",
  component: BookingChangeRequestScreen,
});

export const bookingPaymentsRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/payments",
  component: BookingPaymentsScreen,
});

export const bookingTasksRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/tasks",
  component: BookingTaskScreen,
});
