import { ReactNode } from "react";
import { useField } from "formik";
import { useForm } from "../Hooks/useForm";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

export type GenericFieldProps = {
  name: string;
  label: ReactNode;
  description?: ReactNode;
  className?: string;
  emptyValue?: "" | null;
  optionalLabel?: boolean;
  tooltip?: ReactNode;
  isEditable?: boolean;
  viewNode: ReactNode;
  children: ReactNode;
};

export type InputProps = Partial<GenericFieldProps> &
  Pick<GenericFieldProps, "name" | "label">;

export function GenericField({
  className,
  children,
  description,
  name,
  label,
  optionalLabel,
  tooltip,
  isEditable = true,
  viewNode,
}: GenericFieldProps) {
  const { isEditing } = useForm();

  const [field, baseMeta] = useField(name);
  const { error, touched } = baseMeta;

  if (field.value === undefined) {
    console.log(
      `No value was found for form key: '${name}', either the value is not specified in initialValues OR the value should be null (instead of undefined)`,
    );
  }

  // The error can sometimes be an object, lets check for that and flatten it
  let errorString = "";

  if (Array.isArray(error)) {
    errorString = error
      .map((v) => {
        if (typeof v === "string") return v;

        if (typeof errorString === "object") {
          return Object.values(v as Record<any, string>).join(", ");
        }
      })
      .filter(Boolean)
      .join(", ");
  } else if (typeof error === "object") {
    console.log(error);
    errorString = "TODO";
  } else {
    errorString = error ?? "";
  }

  return (
    <FieldLayout
      label={label}
      optionalLabel={optionalLabel}
      className={className}
      name={name}
      tooltip={tooltip}
    >
      <>
        {isEditing && isEditable ? (
          <div>
            <div className={cn("relative", className)}>{children}</div>
            {description ? (
              <div className="text-sm text-gray-500 mt-2">{description}</div>
            ) : null}
            <div
              className="line-clamp-1 block h-4 truncate text-xs text-red-600"
              title={errorString}
            >
              {touched && errorString ? errorString : null}
            </div>
          </div>
        ) : (
          <div className="border-t border-gray-200">
            <div className="pt-2">{viewNode}</div>
          </div>
        )}
      </>
    </FieldLayout>
  );
}

export function FieldLayout({
  optionalLabel,
  tooltip,
  className,
  name,
  label,
  children,
}: Pick<
  GenericFieldProps,
  "className" | "optionalLabel" | "tooltip" | "name" | "label" | "children"
>) {
  return (
    <div className={cn("space-y-1 min-h-[82px] col-span-3", className)}>
      <Label
        htmlFor={name}
        className="flex h-5 items-center space-x-1 truncate text-sm font-medium text-gray-700"
      >
        <span className="label">{label}</span>
        {optionalLabel ? (
          <span className="ml-2 inline-flex items-center text-gray-500">
            (optional)
          </span>
        ) : null}
        {tooltip ? (
          <Tooltip delayDuration={100}>
            <TooltipTrigger type="button" tabIndex={-1}>
              <QuestionMarkCircleIcon className="h-4 w-4 text-gray-400" />
            </TooltipTrigger>
            <TooltipContent className="bg-gray-700 text-xs text-gray-50">
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        ) : null}
      </Label>

      {children}
    </div>
  );
}
