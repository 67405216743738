import * as Headless from "@headlessui/react";
import { Link as RouterLink } from "@tanstack/react-router";
import React, { ComponentPropsWithoutRef } from "react";

export const Link = React.forwardRef(function Link(
  props: ComponentPropsWithoutRef<typeof RouterLink>,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Headless.DataInteractive>
      <RouterLink {...props} ref={ref} />
    </Headless.DataInteractive>
  );
});
