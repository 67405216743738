export function calculatePercentChange({
  current = 0,
  previous = 0,
}: {
  current?: number;
  previous?: number;
}) {
  const change = current - previous;
  const decimalPercent = change / Math.abs(previous);
  const percent = decimalPercent * 100;

  return { change, percent, decimalPercent };
}
