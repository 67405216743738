import {
  BookingListQueryVariables,
  QueryBookingsOrderByColumn,
  SortOrder,
} from "@/gql/graphql";
import { BookingListSearchParams } from "@/app/Bookings/Routes/bookingRoutes";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";

export function getBookingListQueryVariables({
  bookingStatus,
  bookingSearch,
}: BookingListSearchParams): BookingListQueryVariables {
  const supplierId = getGlobalSupplierId();

  const baseParams: BookingListQueryVariables = {
    first: 20,
    page: 1,
    search: bookingSearch || undefined,
    status: bookingStatus,
    supplierId: supplierId ?? undefined,
  };

  if (bookingSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryBookingsOrderByColumn.CreatedAt,
      },
    ],
  };
}
