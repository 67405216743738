import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { Avatar } from "@/components/catalyst/avatar";

export function OfficeIcon({
  model,
  className,
}: {
  model: SupplierOfficeListItem;
  className?: string;
}) {
  return (
    <Avatar
      media={model.address.locationMedia}
      initials={model.name}
      className={className}
    />
  );
}
