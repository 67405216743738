import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { SupplierTripForm } from "@/app/SupplierTrips/Forms/SupplierTripForm";
import { CreateSupplierTripInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { createSupplierTripValidator } from "@/app/SupplierTrips/Validators/createSupplierTripValidator";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierTripMutation } from "@/app/SupplierTrips/GraphQL/supplierTripMutations";
import { toFormikValidationSchema } from "zod-formik-adapter";

type SupplierTripCreateDialogProps = {
  initialValues?: Partial<CreateSupplierTripInput>;
};
export function SupplierTripCreateDialog({
  initialValues,
}: SupplierTripCreateDialogProps) {
  const { mutateAsync } = useGqlMutation(createSupplierTripMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title="Add trip">
      <GenericForm<CreateSupplierTripInput>
        validationSchema={toFormikValidationSchema(createSupplierTripValidator)}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
        initialValues={{
          supplier: { connect: null! },
          officeA: { connect: null! },
          officeB: { connect: null! },
          distance_allowed: null!,
          hire_units_allowed: null!,
          minimum_hire_units: null!,
          extra_hire_units_allowed: null!,
          is_ferry_required: false,
          inclusions: {
            upsert: [],
            delete: [],
          },
          ...initialValues,
        }}
      >
        <SupplierTripForm />
        <DialogSubmitButton text="Add trip" />
      </GenericForm>
    </GenericDialog>
  );
}
