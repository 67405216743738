import { EmptyRecordScreen } from "@/lib/Components/Screens/EmptyRecordScreen";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
  CalendarCheckIcon,
  CircleDotDashedIcon,
  ClipboardXIcon,
} from "lucide-react";
import { BookingStatus } from "@/gql/graphql";
import { parseParams } from "@/lib/Utils/parseParams";
import {
  bookingCreateRoute,
  bookingsRoute,
} from "@/app/Bookings/Routes/bookingRoutes";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

export function BookingEmptyStateScreen() {
  const { isAdmin } = useTenant();

  return (
    <EmptyRecordScreen
      title="Select a booking"
      flair="Bookings"
      description="Choose a booking from the sidebar to view the details"
      items={[
        ...(isAdmin
          ? [
              {
                name: "Create a booking",
                description: "Manually create a booking.",
                Icon: PlusIcon,
                toOptions: {
                  to: bookingCreateRoute.to,
                  params: {},
                  search: (search: any) => parseParams(search),
                },
              },
            ]
          : []),
        {
          name: "Pending bookings",
          description: "View pending bookings",
          Icon: CircleDotDashedIcon,
          toOptions: {
            to: bookingsRoute.to,
            params: {},
            search: (search) => {
              return {
                ...search,
                bookingStatus: [BookingStatus.AwaitingConfirmation],
              };
            },
          },
        },
        {
          name: "Confirmed bookings",
          description: "View confirmed bookings",
          Icon: CalendarCheckIcon,
          toOptions: {
            to: bookingsRoute.to,
            params: {},
            search: (search) => {
              return {
                ...search,
                bookingStatus: [
                  BookingStatus.Confirmed,
                  BookingStatus.Completed,
                ],
              };
            },
          },
        },
        {
          name: "Cancelled bookings",
          description: "View cancelled bookings",
          Icon: ClipboardXIcon,
          toOptions: {
            to: bookingsRoute.to,
            params: {},
            search: (search) => {
              return {
                ...search,
                bookingStatus: [
                  BookingStatus.AdminCancelled,
                  BookingStatus.CustomerCancelled,
                  BookingStatus.SupplierCancelled,
                ],
              };
            },
          },
        },
      ]}
    />
  );
}
