import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function useSupplierRecord() {
  const match = useMatch({
    from: "/_app/suppliers/$id",
  });
  const { data } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: match.params.id,
  });

  return data.supplier;
}
