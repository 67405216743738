import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { UpdateContactInput } from "@/gql/graphql";
import { ContactEmailsForm } from "@/app/Contacts/Forms/ContactEmailsForm";
import { ContactPhonesForm } from "@/app/Contacts/Forms/ContactPhonesForm";
import { AddressForm } from "@/app/Address/Form/AddressForm";
import { ContactDetailsForm } from "@/app/Contacts/Forms/ContactDetailsForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateContactMutation } from "@/app/Contacts/GraphQL/contactMutations";
import { useContactRecord } from "@/app/Contacts/Hooks/useContactRecord";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { contactEmailValidation } from "@/app/Contacts/Validation/contactValidation";

export function ContactDetailsScreen() {
  const record = useContactRecord();
  const { mutateAsync } = useGqlMutation(updateContactMutation);

  return (
    <div className="py-6 space-y-12">
      <RecordScreenFormCard<UpdateContactInput>
        enableReinitialize={true}
        initialValues={{
          name: record.name,
          company_number: record.company_number,
          legal_name: record.legal_name,
          purchase_order_number: record.purchase_order_number,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Details"
      >
        <ContactDetailsForm contact={record} />
      </RecordScreenFormCard>

      <RecordScreenFormCard<UpdateContactInput>
        validationSchema={toFormikValidationSchema(contactEmailValidation)}
        initialValues={{
          emails: {
            upsert: record.emails.map((email) => ({
              id: email.id,
              email: email.email,
              is_billing: email.is_billing,
              is_primary: email.is_primary,
              is_invoice_reminder: email.is_invoice_reminder,
            })),
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Emails"
      >
        <ContactEmailsForm />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        initialValues={{
          phones: {
            upsert: record.phones.map((phone) => ({
              id: phone.id,
              phone: phone.phone,
            })),
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Phones"
      >
        <ContactPhonesForm />
      </RecordScreenFormCard>

      <RecordScreenFormCard<UpdateContactInput>
        initialValues={{
          billingAddress: {
            upsert: {
              id: record.billingAddress?.id,
              city: record.billingAddress?.city ?? null,
              country: record.billingAddress?.country ?? null,
              postcode: record.billingAddress?.postcode ?? null,
              name: record.billingAddress?.name ?? null,
              lat: record.billingAddress?.lat ?? null,
              lng: record.billingAddress?.lng ?? null,
              place_id: record.billingAddress?.place_id ?? null,
              address_line_1: record.billingAddress?.address_line_1 ?? null,
              address_line_2: record.billingAddress?.address_line_2 ?? null,
              state: record.billingAddress?.state ?? null,
            },
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Billing address"
      >
        <AddressForm prefix="billingAddress.upsert" />
      </RecordScreenFormCard>
    </div>
  );
}
