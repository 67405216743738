import { graphql } from "@/gql";

export const sidebarCountsQuery = graphql(`
  query SidebarCounts {
    bookingsCount {
      status
      count
    }
    invoiceCounts {
      count
      status
    }
  }
`);
