import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { useRef } from "react";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { GoogleMapsAutocomplete } from "@/app/Address/Components/GoogleMapsAutocomplete";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Map } from "@/lib/GoogleMaps/Components/Map";
import { CityInputRes } from "@/app/Cities/Cities/GraphQL/cityInputQuery";

type AddressFormComponentProps = {
  prefix?: string;
  initialPlaceId?: string;
  initialSearch?: string;
  city?: CityInputRes;
};

export function AddressForm({
  prefix = "",
  initialSearch,
  city,
}: AddressFormComponentProps) {
  const { getFieldProps, isEditing } = useForm();

  const lat = getFieldProps(`${prefix}.lat`).value;
  const lng = getFieldProps(`${prefix}.lng`).value;

  const hasLatLng = !!lat && !!lng;

  const addressLine1Ref = useRef<HTMLInputElement | null>(null);
  const addressLine2Ref = useRef<HTMLInputElement | null>(null);

  return (
    <div className="col-span-full grid grid-cols-12 gap-x-6">
      <div className="p-6 pb-0 mb-6 bg-gray-100 col-span-full rounded-md">
        {isEditing ? (
          <GoogleMapsAutocomplete
            prefix={prefix}
            placeholder="Search for an address"
            initialSearch={initialSearch}
            city={city}
            onSelect={() => {
              addressLine2Ref.current?.focus();
            }}
          />
        ) : null}
        {!hasLatLng ? (
          <WarningBanner className="mt-6">
            <p>Please search for an address to populate these fields.</p>
          </WarningBanner>
        ) : null}

        <fieldset
          disabled={!hasLatLng}
          className="grid grid-cols-12 gap-6 pt-6 disabled:opacity-50"
        >
          <Map
            className="col-span-full mb-6 overflow-hidden rounded-b-md"
            height={200}
            position={{
              lat: lat ?? 0,
              lng: lng ?? 0,
            }}
          />

          <TextInput
            name={`${prefix}.name`}
            label="Name"
            className="col-span-4"
            inputProps={{
              ref: addressLine2Ref,
            }}
          />
          <TextInput
            name={`${prefix}.place_id`}
            label="Place ID"
            className="col-span-4"
            isEditable={false}
            tooltip="Googles unique identifier for this place. Used for geocoding."
          />
          <TextInput
            name={`${prefix}.lat`}
            label="Lat"
            className="col-span-2"
            isEditable={false}
          />
          <TextInput
            name={`${prefix}.lng`}
            label="Lng"
            className="col-span-2"
            isEditable={false}
          />

          <TextInput
            name={`${prefix}.address_line_2`}
            label="Apartment, unit, suite, or floor #"
            className="col-span-3"
            inputProps={{
              ref: addressLine2Ref,
            }}
          />
          <TextInput
            name={`${prefix}.address_line_1`}
            label="Address line 1"
            className="col-span-9"
            inputProps={{
              ref: addressLine1Ref,
            }}
          />
          <TextInput
            name={`${prefix}.city`}
            label="City"
            className="col-span-3"
          />
          <TextInput
            name={`${prefix}.state`}
            label="State"
            className="col-span-3"
          />
          <TextInput
            name={`${prefix}.postcode`}
            label="Postcode"
            className="col-span-3"
          />
          <TextInput
            name={`${prefix}.country`}
            label="Country"
            className="col-span-3"
          />
        </fieldset>
      </div>
    </div>
  );
}
