import { CellContext } from "@tanstack/react-table";
import {
  BookingListQueryVariables,
  BookingStatus,
  QueryBookingsOrderByColumn,
  SortOrder,
} from "@/gql/graphql";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useState } from "react";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingFindRelocationDialog } from "@/app/Bookings/Components/BookingFindRelocationDialog";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { CopyCheckIcon, CopyIcon } from "lucide-react";
import { toast } from "sonner";
import { formatDateTime } from "@/lib/Formatters/formatDateTime";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  BookingListItem,
  bookingListQuery,
} from "@/app/Bookings/GraphQL/bookingListQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import {
  MultiSelectFilter,
  MultiSelectFilterOption,
} from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { TableId } from "@/app/Common/Utils/tableIds";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import { RelocationDetailsDialog } from "@/app/Relocations/Components/RelocationDetailsDialog";
import { useBookingActions } from "@/app/Bookings/Hooks/useBookingActions";

type BookingStatusType = "pending" | "confirmed" | "completed" | "cancelled";

const options: MultiSelectFilterOption<BookingStatusType>[] = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

const statusTypeToStatusMap: Record<BookingStatusType, BookingStatus[]> = {
  pending: [BookingStatus.AwaitingConfirmation, BookingStatus.Vip],
  confirmed: [BookingStatus.Confirmed],
  completed: [BookingStatus.Completed],
  cancelled: [
    BookingStatus.AdminCancelled,
    BookingStatus.SupplierCancelledNotAvailable,
    BookingStatus.SupplierCancelledNoFerry,
    BookingStatus.SupplierCancelledMechanicalFailure,
    BookingStatus.SupplierCancelled,
    BookingStatus.CustomerCancelled,
    BookingStatus.CustomerCancelledNoShow,
    BookingStatus.CustomerCancelledRebooked,
    BookingStatus.CustomerCancelledConfirmationTimeliness,
  ],
};

type DashboardPendingOrdersTableProps = {
  queryVariables?: Partial<BookingListQueryVariables>;
  title?: string;
  enabledStatuses?: BookingStatusType[];
  hiddenColumns?: BookingColumnId[];
  id: TableId;
};

type BookingColumnId =
  | "booking"
  | "status"
  | "matches"
  | "relocation"
  | "dates"
  | "customer"
  | "pax"
  | "trip"
  | "actions"
  | "created_at";

const sortMap: { [key in BookingColumnId]?: QueryBookingsOrderByColumn } = {
  status: QueryBookingsOrderByColumn.Status,
  created_at: QueryBookingsOrderByColumn.CreatedAt,
  booking: QueryBookingsOrderByColumn.Reference,
  dates: QueryBookingsOrderByColumn.DepartAt,
  customer: QueryBookingsOrderByColumn.Name,
};

export function BookingTable({
  queryVariables,
  title,
  id,
  enabledStatuses = [],
  hiddenColumns,
}: DashboardPendingOrdersTableProps) {
  const { supplier } = useGlobalSupplier();
  const getActions = useBookingActions();

  const { isAdmin } = useTenant();

  const columns: DataTableColDef<BookingListItem, unknown, BookingColumnId>[] =
    [
      {
        id: "booking",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Booking" />
        ),
        accessorFn: (row) => row,
        cell: BookingCell,
      },
      {
        id: "status",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Status" />
        ),
        accessorFn: (row) => row,
        cell: StatusCell,
      },
      {
        id: "matches",
        header: "Matches",
        cell: MatchedRelocationsCell,
        isHidden: !isAdmin,
      },
      {
        id: "relocation",
        header: "Relocation",
        cell: ({ row }) => {
          return <RelocationCell booking={row.original} />;
        },
      },
      {
        id: "dates",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Dates" />
        ),
        accessorFn: (row) => row,
        size: 200,
        cell: DateCell,
      },
      {
        id: "customer",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Driver" />
        ),
        accessorFn: (row) => row,
        cell: CustomerCell,
      },
      {
        id: "pax",
        header: "Pax",
        cell: ({ row }) => (
          <div className="text-gray-500">
            {row.original.number_of_travellers}
          </div>
        ),
      },
      {
        id: "trip",
        header: "Trip",
        cell: TripCell,
      },
      {
        id: "created_at",
        accessorFn: (row) => row,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Created" />
        ),
        cell: ({ row }) => (
          <TimeSinceNow
            className="text-gray-500 text-xs"
            time={row.original.created_at}
          />
        ),
      },
    ];

  const [status, setStatus] = useState<BookingStatusType[]>(enabledStatuses);

  return (
    <DataTable
      id={id}
      title={title}
      hiddenColumns={hiddenColumns}
      getActions={getActions}
      document={bookingListQuery}
      columns={columns}
      accessor={(data) => data.bookings}
      filters={
        <div className="space-x-2">
          <MultiSelectFilter
            label="Status"
            options={options}
            selected={status}
            onChange={setStatus}
          />
        </div>
      }
      getQueryVariables={({ pagination, sorting, search }) => {
        return {
          supplierId: supplier?.id,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          search,
          status:
            status.length > 0
              ? status.flatMap((s) => statusTypeToStatusMap[s])
              : undefined,
          orderBy: search
            ? undefined
            : sorting.length
              ? sorting.map((s) => {
                  const column = sortMap[s.id];

                  if (!column) {
                    throw new Error("No sort configured for " + s.id);
                  }

                  return {
                    column,
                    order: s.desc ? SortOrder.Desc : SortOrder.Asc,
                  };
                })
              : [
                  {
                    order: SortOrder.Desc,
                    column: QueryBookingsOrderByColumn.CreatedAt,
                  },
                ],
          ...queryVariables,
        };
      }}
    />
  );
}

export function RelocationCell({ booking }: { booking: BookingListItem }) {
  const relocation = booking.relocation;
  const { hasSupplier } = useGlobalSupplier();

  return (
    <RelocationDetailsDialog relocation={booking.relocation}>
      <p>
        <Link
          title={relocation.id}
          className="underline text-blue-500 truncate"
          to="/relocations/$relocationId"
          params={{
            relocationId: relocation.id,
          }}
        >
          {relocation.departureOffice.name} to {relocation.deliveryOffice.name}
        </Link>
      </p>
      {!hasSupplier ? (
        <p className="text-xs font-bold">{relocation.supplier.name}</p>
      ) : null}
      <p className="text-xs text-gray-500">{relocation.vehicle.name}</p>
      <ul>
        {relocation.inclusions.map(({ id, type, value, description }) => {
          const Icon = inclusionIconMap[type];

          return (
            <li
              key={id}
              className="space-x-2 flex items-center text-xs text-gray-500"
            >
              <Icon className="w-4 h-4 text-yellow-400 flex-shrink-0" />
              {value ? (
                <span>{formatCurrency(value, relocation.currency)}</span>
              ) : null}
              <div
                className="truncate line-clamp-1 whitespace-pre-line"
                dangerouslySetInnerHTML={{
                  __html: description ?? "--",
                }}
              />
            </li>
          );
        })}
      </ul>
    </RelocationDetailsDialog>
  );
}

function MatchedRelocationsCell({ row }: CellContext<BookingListItem, any>) {
  const { open } = useDialog(BookingFindRelocationDialog);

  return (
    <TextButton
      intent="primary"
      onClick={() => {
        open({
          bookingId: row.original.id,
        });
      }}
    >
      {row.original.matchedRelocationsCount}
    </TextButton>
  );
}

function BookingCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  const hasLineExpired = booking.relocationLineReference?.line.expires_at
    ? dayjs(booking.relocationLineReference?.line.expires_at, {
        utc: true,
      }).isBefore(dayjs())
    : false;

  return (
    <div className="flex flex-col">
      <p>
        <Link
          to={"/bookings/$bookingId"}
          params={{
            bookingId: booking.id,
          }}
          className="underline text-blue-500"
        >
          {booking.reference}
        </Link>
      </p>
      {booking.relocationLineReference ? (
        <>
          <p
            className={cn("font-bold truncate", {
              "text-red-500": hasLineExpired,
            })}
            title={booking.relocationLineReference.reference}
          >
            {booking.relocationLineReference?.reference}
            {hasLineExpired ? (
              <span className="text-xs font-normal"> (expired)</span>
            ) : null}
          </p>
        </>
      ) : null}
    </div>
  );
}

function StatusCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;
  const { t } = useTranslation("booking");

  return (
    <Badge color={bookingStatusColorMap[booking.status]}>
      {t(`statusShortLabel.${booking.status}`)}
    </Badge>
  );
}

function DateCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;
  return (
    <div className="text-xs">
      <CopyToClipboard text={formatDateTime(booking.depart_at)} />
      <CopyToClipboard text={formatDateTime(booking.deliver_at)} />
    </div>
  );
}

function TripCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  const { t } = useTranslation("relocation");
  const hireUnitLabel = t(
    `hire_unit_type.${booking.relocation.hire_unit_type}`,
  );

  let unitLabel = `${booking.discounted_units}`;
  if (booking.full_price_units > 0) {
    unitLabel += ` + ${booking.full_price_units}`;
  }
  unitLabel += ` ${hireUnitLabel}s`;

  return (
    <div className="text-xs text-gray-500">
      <p className="">{unitLabel}</p>
      <p>
        {formatDistance(
          booking.distance_allowed,
          booking.relocation.measurement,
        )}
      </p>
      <p className="">{booking.relocation.supplierInsuranceOption?.name}</p>
    </div>
  );
}

export function CustomerCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  return (
    <div className="text-xs">
      <p>
        <CopyToClipboard text={booking.name} />
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={booking.email} />
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={booking.phone} />
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={formatDate(booking.date_of_birth)} />
      </p>
    </div>
  );
}

export function CopyToClipboard({
  text,
  children,
}: {
  text: string | null;
  children?: React.ReactNode;
}) {
  const [copied, setCopied] = useState(false);

  if (!text) return <div>--</div>;

  return (
    <button
      className="flex items-center group/copy relative space-x-2 active:ring-1 ring-offset-2 rounded-md ring-gray-500 text-left"
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        toast.success("Success", {
          description: `'${text}' to clipboard`,
        });

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      <span>{children ? children : text}</span>
      <span className="opacity-0 group-hover/copy:opacity-100 transition duration-300 absolute -right-4">
        {copied ? (
          <CopyCheckIcon className="w-3 h-3" />
        ) : (
          <CopyIcon className="w-3 h-3" />
        )}
      </span>
    </button>
  );
}
