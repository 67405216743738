import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { FileUploader } from "@/lib/FileUpload/FileUploader";
import { MediaCollection, MediaFieldsFragment, ModelType } from "@/gql/graphql";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { SortableList } from "@/app/Vehicles/Components/Sortable";
import { Button } from "@/lib/Components/Button/Button";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  createMediaMutation,
  deleteMediaMutation,
  mediaSetNewOrderMutation,
} from "@/app/Media/GraphQL/mediaMutations";
import { VehicleRecord } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { Image } from "@/app/Media/Components/Image";

export function SortableVehicleImages({ record }: { record: VehicleRecord }) {
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);
  const [items, setItems] = useState(record.images);
  const { mutateAsync: setNewOrder } = useGqlMutation(mediaSetNewOrderMutation);

  const isOrderDifferent =
    items.map((item) => item.id).join(",") !==
    record.images.map((item) => item.id).join(",");

  //Watch the size of the images array and reset the items if it changes
  useEffect(() => {
    setItems(record.images);
  }, [record.images.length, record.id]);

  return (
    <RecordScreenCard
      title="Images"
      buttons={
        isOrderDifferent ? (
          <Button
            onClick={async () => {
              await setNewOrder({
                ids: items.map((item) => item.id),
              });
            }}
          >
            Save changes
          </Button>
        ) : null
      }
    >
      <ul className="grid grid-cols-3 gap-6">
        {record.images.length > 0 ? (
          <SortableList
            items={items}
            onChange={setItems}
            renderItem={(item) => {
              return (
                <SortableList.Item id={item.id}>
                  <MediaPreview media={item} />
                  <SortableList.DragHandle />
                </SortableList.Item>
              );
            }}
          />
        ) : (
          <li>
            <img src={record.heroImage} alt="default image" />
            <div className="text-gray-500 text-xs mt-1">
              This is the default image for a {record.type}.
            </div>
          </li>
        )}
      </ul>

      <div className="pt-10">
        <FileUploader
          onUpload={(uploads) => {
            uploads.map((upload) => {
              return createMedia({
                input: {
                  file_name: upload.file.name,
                  objectId: record.id,
                  objectType: ModelType.Vehicle,
                  collectionName: MediaCollection.Images,
                  key: upload.temporary_url,
                },
              });
            });
          }}
        />
      </div>
    </RecordScreenCard>
  );
}

function MediaPreview({ media }: { media: MediaFieldsFragment }) {
  const { mutateAsync: deleteMedia, isPending } =
    useGqlMutation(deleteMediaMutation);

  return (
    <div className="relative">
      <Image media={media} />
      <div className="top-1 right-1 absolute">
        <IconButton
          isLoading={isPending}
          Icon={TrashIcon}
          intent="danger"
          tooltip="Delete"
          onClick={async () => {
            await deleteMedia({
              id: media.id,
            });
          }}
        />
      </div>
    </div>
  );
}
