import {
  SidebarList,
  SidebarListItemProps,
} from "@/lib/Components/SideBarList/SideBarList";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FilterIcon } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { useDeferredValue, useEffect, useState } from "react";
import {
  paymentListRoute,
  paymentRoute,
} from "@/app/Payments/Routes/paymentRoute";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { useTranslation } from "react-i18next";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  PaymentListItem,
  paymentListQuery,
} from "@/app/Payments/GraphQL/paymentListQuery";
import { keepPreviousData } from "@tanstack/react-query";
import { getPaymentListQueryVariables } from "@/app/Payments/Utils/getPaymentListQueryVariables";
import { Badge } from "@/components/catalyst/badge";
import { paymentStatusIntentMap } from "@/app/Payments/Utils/paymentStatusIntentMap";

export function PaymentListScreen() {
  const searchParams = paymentListRoute.useSearch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(searchParams.paymentSearch ?? "");
  const deferredSearch = useDeferredValue(search);

  useEffect(() => {
    navigate({
      search: (s) => ({
        ...s,
        paymentSearch: deferredSearch || undefined,
      }),
    });
  }, [deferredSearch]);

  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useInfiniteGqlQuery(
      paymentListQuery,
      getPaymentListQueryVariables(searchParams),
      {
        placeholderData: keepPreviousData,
        initialPageParam: {
          page: 1,
        },
        getNextPageParam: () => {},
      },
    );

  const items = data?.pages.flatMap((p) => p.payments.data);
  const totalCount = data?.pages.at(0)?.payments.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      onClearFilters={() => {
        setSearch("");
      }}
      listRoute={"/payments"}
      search={search}
      onSearchChange={setSearch}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      items={items}
      totalCount={totalCount}
      itemNode={(item) => <PaymentItem model={item} />}
      linkProps={(item) => ({
        to: paymentRoute.to,
        params: {
          id: item.id,
        },
      })}
    />
  );
}

function PaymentItem({ model }: SidebarListItemProps<PaymentListItem>) {
  const { t } = useTranslation("payment");

  return (
    <div className="">
      <p className="text-sm">Payment #{model.id}</p>
      <p className="text-xs text-gray-500">
        {formatCurrency(model.amount, model.currency)}
      </p>

      <p>
        <Badge color={paymentStatusIntentMap[model.status]}>
          {t(`status.${model.status}`)}
        </Badge>
      </p>

      <p className="text-xs text-gray-500">
        <time dateTime={model.created_at}>
          {formatDateTimeSinceNow(model.created_at)}
        </time>
      </p>
    </div>
  );
}

type StandardEnum = Record<string, string | number>;

type StringLikeValues<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : never;
}[keyof T];
type StringValues<T> =
  StringLikeValues<T> extends string ? `${StringLikeValues<T>}` : never;

type EnumCompatibleValue<Enum extends StandardEnum> =
  | Enum[keyof Enum]
  | StringValues<Enum>;

type StatusFilterProps<T extends StandardEnum> = {
  statusEnum: T;
  checkedStatuses?: EnumCompatibleValue<T>[];
  onStatusesUpdated?: (enabledStatuses: any[] | undefined) => void;
  onCheckedChange?: (val: EnumCompatibleValue<T>, checked: boolean) => void;
};
export function StatusFilter<T extends StandardEnum>({
  statusEnum,
  checkedStatuses,
  onCheckedChange,
  onStatusesUpdated,
}: StatusFilterProps<T>) {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <FilterIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Status</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {Object.entries(statusEnum).map(([, value]) => {
            return (
              <DropdownMenuCheckboxItem
                onSelect={(e) => {
                  e.preventDefault();
                }}
                key={value as string}
                checked={checkedStatuses?.includes(value as any)}
                onCheckedChange={async (checked) => {
                  onCheckedChange?.(value as any, checked);

                  if (onStatusesUpdated) {
                    const newStatuses = new Set(checkedStatuses ?? []);

                    if (checked) {
                      newStatuses.add(value as any);
                    } else {
                      newStatuses.delete(value as any);
                    }

                    onStatusesUpdated(Array.from(newStatuses));
                  }
                }}
              >
                {value}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
