import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";
import { DocumentTextIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { ActivityIcon } from "lucide-react";
import { useMatch } from "@tanstack/react-router";
import { vehicleRoute } from "@/app/Vehicles/Routes/vehicleRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { vehicleRecordQuery } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { useVehicleActions } from "@/app/Vehicles/Hooks/useVehicleActions";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { Badge } from "@/components/catalyst/badge";

export function VehicleRecordScreen() {
  const match = useMatch({
    from: vehicleRoute.id,
  });

  const { data } = useSuspenseGqlQuery(vehicleRecordQuery, {
    id: match.params.id,
  });

  const vehicle = data.vehicle;

  const actions = useVehicleActions()(vehicle);

  return (
    <RecordScreen
      actionsGroups={actions}
      title={vehicle.name}
      subtitle={`Created ${formatDateTimeSinceNow(vehicle.created_at)}`}
      badges={
        vehicle.archived_at ? <Badge color="purple">archived</Badge> : null
      }
      tabs={[
        {
          name: "Details",
          to: "/vehicles/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Images",
          to: "/vehicles/$id/images",
          Icon: PhotoIcon,
        },
        {
          name: "Activity",
          to: "/vehicles/$id/activity",
          Icon: ActivityIcon,
        },
      ]}
      titleIcon={<VehicleIcon model={vehicle} />}
    />
  );
}
