import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { OpenHoursViewState } from "@/app/Offices/Components/OpenHoursViewState";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { Link } from "@tanstack/react-router";
import {
  fromOpenDaysSchema,
  toOpenDaysSchema,
} from "@/app/Suppliers/Utils/mapClosedDays";
import { ClosedDatesForm } from "@/app/Suppliers/Components/ClosedDatesForm";
import { IllustrationCalendar } from "@/assets/Illustrations";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierOfficeMutation } from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";

export function OfficeOpenHoursScreen() {
  const record = useOfficeRecord();
  const { mutateAsync } = useGqlMutation(updateSupplierOfficeMutation);

  return (
    <>
      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: {
              departure_hours: values.custom_hours
                ? values.departure_hours
                : null,
            },
          });
        }}
        initialValues={{
          custom_hours: record.departure_hours !== null,
          departure_hours:
            record.departure_hours ?? record.supplier.departure_hours,
        }}
        title="Departure hours"
        view={<InheritedFromSupplierBanner type="departure_hours" />}
      >
        <SupplierOfficeOpenHoursForm name={`departure_hours`} />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          await mutateAsync({
            id: record.id,
            input: {
              delivery_hours: values.custom_hours
                ? values.delivery_hours
                : null,
            },
          });
          resetForm();
        }}
        initialValues={{
          custom_hours: record.delivery_hours !== null,
          delivery_hours:
            record.delivery_hours ?? record.supplier.delivery_hours,
        }}
        title="Delivery hours"
        view={<InheritedFromSupplierBanner type="delivery_hours" />}
      >
        <SupplierOfficeOpenHoursForm name={`delivery_hours`} />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        onSubmit={async (values) => {
          const closedDates = toOpenDaysSchema(values.closed_dates);
          await mutateAsync({
            id: record.id,
            input: {
              closed_dates: closedDates,
            },
          });
        }}
        title="Closed days"
        initialValues={{
          closed_dates: fromOpenDaysSchema(record.closed_dates),
        }}
      >
        <ClosedDatesForm
          emptyState={
            <EmptyState
              Icon={IllustrationCalendar}
              header="No closed dates"
              subheader="Add date(s) you want to close this office on"
            />
          }
        />
      </RecordScreenFormCard>
    </>
  );
}

export function SupplierOfficeOpenHoursForm({ name }: { name: string }) {
  const { values } = useForm();

  return (
    <>
      <SwitchInput
        name="custom_hours"
        label="Custom hours"
        className="col-span-full"
      />
      {values.custom_hours ? <OpenHoursInput prefix={name} /> : null}
    </>
  );
}

function InheritedFromSupplierBanner({
  type,
}: {
  type: "delivery_hours" | "departure_hours";
}) {
  const record = useOfficeRecord();

  if (record[type] !== null) {
    return (
      <OpenHoursViewState
        openHours={record[type]}
        parentOpenHours={record.supplier[type]}
      />
    );
  }

  return (
    <div className="space-y-12">
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              The {type} are inherited from the supplier.
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <Link
                to={"/suppliers/$id/default-hours"}
                params={{
                  id: record.supplier.id,
                }}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                search={{}}
              >
                View suppliers hours
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>

      <OpenHoursViewState openHours={record.supplier[type]} />
    </div>
  );
}
