import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateSupplierOfficeInput,
  QueryCitiesOrderByColumn,
  SortOrder,
} from "@/gql/graphql";
import { useState } from "react";
import { AddressForm } from "@/app/Address/Form/AddressForm";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { CityListItem } from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierOfficeForm() {
  const { values, touched, initialValues, setFieldValue } =
    useForm<CreateSupplierOfficeInput>();

  const [city, setCity] = useState<CityListItem | null>(null);

  const { data: res } = useGqlQuery(
    supplierRecordQuery,
    {
      id: values.supplier?.connect!,
    },
    {
      enabled: !!values.supplier?.connect,
    },
  );

  return (
    <>
      {!initialValues.supplier?.connect ? (
        <SupplierInput
          name="supplier.connect"
          label="Supplier"
          className="col-span-4"
        />
      ) : null}

      {values.supplier?.connect ? (
        <>
          <CityInput
            name="city.connect"
            label="City"
            className="col-span-4"
            getQueryVariables={(search) => {
              return {
                page: 1,
                first: 200,
                search,
                region: res?.supplier.region,
                orderBy: !search
                  ? [
                      {
                        order: SortOrder.Asc,
                        column: QueryCitiesOrderByColumn.Name,
                      },
                    ]
                  : undefined,
              };
            }}
            onChange={async (newCity) => {
              if (touched.name) return;

              await setFieldValue("name", `${newCity?.name}`);
              setCity(newCity);
            }}
          />

          {values.city?.connect ? (
            <>
              <FormDivider />
              <TextInput name={"name"} label={"Name"} className="col-span-4" />
              <TextInput
                name="code"
                label="Code"
                className="col-span-4"
                optionalLabel
              />
              <FormDivider />

              <AddressForm prefix="address.upsert" city={city ?? undefined} />
              <RichTextInput
                optionalLabel
                name="pickup_instructions"
                label="Pickup instructions"
                className="col-span-full"
              />
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
