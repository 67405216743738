import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  forwardRef,
  ReactNode,
  useState,
} from "react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type IconButtonProps = {
  Icon: FC<any>;
  intent?: IconButtonIntent;
  size?: number;
  /** @deprecated */
  padding?: number;
  isLoading?: boolean;
  tooltip: ReactNode;
} & ButtonProps;

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type IconButtonIntent = "danger" | "neutral" | "success";

const intentStyleMap: {
  [index in IconButtonIntent]: string;
} = {
  danger: "text-red-500 hover:bg-red-50 active:bg-red-100 focus:ring-red-200",
  neutral:
    "text-gray-500 hover:bg-blue-50 active:bg-blue-100 focus:ring-blue-200",
  success:
    "text-green-500 hover:bg-green-50 active:bg-green-100 focus:ring-green-200",
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      Icon,
      intent = "neutral",
      className,
      size = 5,
      padding = 2,
      isLoading = false,
      children,
      tooltip,
      onClick,
      ...props
    },
    ref,
  ) => {
    const [isInternalLoading, setInternalLoading] = useState(false);

    const showLoading = isLoading || isInternalLoading;

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            ref={ref}
            type="button"
            {...(props as any)}
            onClick={async (e) => {
              e.stopPropagation();
              setInternalLoading(true);
              try {
                await onClick?.(e as any);
              } finally {
                setInternalLoading(false);
              }
            }}
            className={cn(
              "relative flex rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring focus:ring-inset disabled:cursor-not-allowed disabled:opacity-50 disabled:shadow-none disabled:ring-0 disabled:text-gray-500",
              intentStyleMap[intent],
              `p-${padding}`,
              className,
            )}
          >
            {showLoading ? (
              <Spinner className={`h-${size} w-${size}`} />
            ) : (
              <Icon className={`h-${size} w-${size}`} />
            )}
            {children}
          </button>
        </TooltipTrigger>
        <TooltipContent
          className="whitespace-nowrap rounded-md bg-gray-500 px-3 py-1 text-xs text-gray-50"
          asChild
        >
          <span>{tooltip}</span>
        </TooltipContent>
      </Tooltip>
    );
  },
);
IconButton.displayName = "IconButton";
