import { OrganisationRecord } from "@/app/Organisations/GraphQL/organisationRecordQuery.graphql";
import { Avatar } from "@/components/catalyst/avatar";

export function OrganisationLogo({
  organisation,
}: {
  organisation: OrganisationRecord;
}) {
  if (!organisation) return null;

  const logo = organisation.contact.profilePicture;

  return (
    <Avatar media={logo} initials={organisation.name} className="h-12 w-12" />
  );
}
