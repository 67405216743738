import { graphql } from "@/gql";

export const createWaitlistMutation = graphql(`
  mutation CreateWaitlist($input: CreateWaitlistInput!) {
    createWaitlist(input: $input) {
      id
    }
  }
`);

export const updateWaitlistMutation = graphql(`
  mutation UpdateWaitlist($id: ID!, $input: UpdateWaitlistInput!) {
    updateWaitlist(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteWaitlistMutation = graphql(`
  mutation DeleteWaitlist($id: ID!) {
    deleteWaitlist(id: $id) {
      id
    }
  }
`);

export const sendWaitlistMutation = graphql(`
  mutation SendWaitlistMatchesEmail($id: ID!) {
    sendWaitlistMatchesEmail(id: $id) {
      id
    }
  }
`);
